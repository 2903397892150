<template>
  <form 
    id="form-process-video"
    data-test-id="form-process-video"
    novalidate
  >
    <div class="form-group">
      <label>{{ getLiterals.videoFormComponent.videoToAnalyze }}</label>
      <p
        class="form-errors text-left"
        v-if="formErrors.videoUrl"
        data-test-id="input-error-text-video-url">
        {{ formErrors.videoUrl }}
      </p>
      <input
        type="url"
        class="form-control"
        placeholder="url"
        :class="videoUrlInputvalidation"
        v-model="formData.videoUrl"
        id="input-video-url"
        aria-describedby="Input use to search youtube video url"
        data-test-id="input-video-url"
        required
      />
      <div class="form-group">
        <div class="form-group pt-2">
          <label>{{ getLiterals.videoFormComponent.whereSendResult }}</label>
          <p
            class="form-errors text-left"
            v-if="formErrors.email"
            data-test-id="input-error-text-video-url">
            {{ formErrors.email }}
          </p>
          <input
            type="email"
            class="form-control"
            :class="emailInputValidation"
            placeholder="email"
            v-model="formData.email"
            id="input-video-email"
            aria-describedby="Input with email to send youtube video analisis result"
            data-test-id="input-video-email"
            required/>
          <div class="form-check text-left mt-3">
            <input
              class="form-check-input"
              type="checkbox"
              id="video-check-consent"
              value="on"
              data-test-id="input-video-check-consent"
              v-model="formData.consentCheck"
            />
            <label class="form-check-label" for="video-check-consent">
              {{ getLiterals.videoFormComponent.agreeConditions }}
            </label
            >
          </div>
        </div>
      </div>
    </div>
    <button
      type="submit" class="btn btn-outline-dark mt-3 disabled" href="#"
      data-toggle="modal"
      @click.prevent="processVideo"
      :disabled="true"
      id="button-send-process-video"
      data-test-id="button-send-process-video"
    >
      {{ getLiterals.videoFormComponent.wannaTry }}
    </button>
  </form>
</template>

<script>
import utils from '@/utils/methods';
import youtubeApi from '@/api/youtube';
import consentApi from '@/api/consent';
import literalsMixin from '@/mixins/literals_mixin';
import videoFormSchema from '../schemas/analize_video_form_joi';
import literals from '../literals/index';
import constants from '../constants/index';

export default {
  name: 'ProcessVideoForm',
  mixins: [literalsMixin],
  data() {
    return {
      formErrors: {
        videoUrl: '',
        email: '',
      },
      formData: {
        videoUrl: '',
        email: '',
        consentCheck: false,
        consentCheckMarketing: false,
      },
      isLoading: false,
    };
  },
  computed: {
    emailInputValidation() {
      if (this.formErrors.email || this.formErrors.videoUrl) {
        return this.formErrors.email ? 'is-invalid' : 'is-valid';
      }
      return '';
    },
    videoUrlInputvalidation() {
      if (this.formErrors.email || this.formErrors.videoUrl) {
        return this.formErrors.videoUrl ? 'is-invalid' : 'is-valid';
      }
      return '';
    },
  },
  methods: {
    async processVideo() {
      this.formErrors.videoUrl = '';
      this.formErrors.email = '';
      const validation = videoFormSchema.validate(this.formData, {
        abortEarly: false,
      });
      const videoId = utils.getYoutubeVideoId(this.formData.videoUrl);
      if (!validation.error && videoId) {
        this.isLoading = true;
        const requests = [];
        const self = this;
        requests.push(
          youtubeApi
            .sendProcessVideoRequest(videoId, this.formData.email)
            .catch((err) => {
              if (err.response.data.errorTitle && err.response.data.description) {
                self.$store.commit('addToastData', {
                  title: err.response.data.errorTitle,
                  body: err.response.data.description,
                  category: constants.toastStatus.error,
                });
              } else {
                self.$store.commit('addToastData', {
                  title: literals.errors[self.$store.state.lang].UknownAPIProcessVideoErrorTitle,
                  body: literals.errors[self.$store.state.lang].UknownAPIErrorMessage,
                  category: constants.toastStatus.error,
                });
              }
            }),
        );
        if (this.formData.consentCheckMarketing) {
          requests.push(
            consentApi.sendConsentData(this.formData.email).catch((err) => {
              if (err.response.data.errorTitle && err.response.data.description) {
                self.$store.commit('addToastData', {
                  title: err.response.data.errorTitle,
                  body: err.response.data.description,
                  category: constants.toastStatus.error,
                });
              } else {
                self.$store.commit('addToastData', {
                  title: literals.errors[self.$store.state.lang].UknownAPIConsentErrorTitle,
                  body: literals.errors[self.$store.state.lang].UknownAPIErrorMessage,
                  category: constants.toastStatus.error,
                });
              }
            }),
          );
        }

        await Promise.all(requests).then((values) => {
          self.isLoading = false;

          // video response
          const videoResponse = values[0];
          if (videoResponse && videoResponse.status === 202) {
            self.$store.commit('addToastData', {
              title: literals.forms[self.$store.state.lang].ToastVideoProcessingTitle,
              body: literals.forms[self.$store.state.lang].videoIsProcessingSucessfull,
              category: constants.toastStatus.successed,
            });
          } else if (videoResponse && videoResponse.status === 200) {
            self.$store.commit('addS3SignedUrl', {
              url: videoResponse.data.presignedUrl,
              id: videoId,
            });

            self.$store.commit('addToastData', {
              title: literals.forms[this.$store.state.lang].ToastVideoProcessingTitle,
              body: literals.forms[this.$store.state.lang].videoIsAlreadyProccesed,
              category: constants.toastStatus.successed,
            });
            self.$router.push(`/youtube/${videoId}`);
          }
        });
      } else if (!videoId && validation.error) {
        this.checkFormErrors(validation.error.details);
        this.formErrors.videoUrl = literals
          .forms[this.$store.state.lang].invalidUrlInputErrorMsg;
      } else if (!videoId && !validation.error) {
        this.formErrors.videoUrl = literals
          .forms[this.$store.state.lang].invalidUrlInputErrorMsg;
      } else {
        this.checkFormErrors(validation.error.details);
      }
    },
    checkFormErrors(errors) {
      const self = this;
      errors.forEach((error) => {
        switch (error.context.key) {
          case 'videoUrl':
            self.formErrors.videoUrl = error.message;
            break;
          case 'email':
            self.formErrors.email = error.message;
            break;
          default:
            break;
        }
      });
    },
  },
};
</script>

<style lang="scss">
  .spinner-border {
    margin-right: 1rem;
    margin-bottom: 0.4rem;
  }
</style>
