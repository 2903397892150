import axios from 'axios';

export default {
  sendProcessSearchRequest(searchText, email) {
    const url = `${process.env.VUE_APP_MEDIA_DATA_API_URL}/twitter`;

    return axios.post(url, {
      searchText: searchText,
      email: email,
    },
    {
      headers: { 'Accept-Language': navigator.language },
    });
  },
};
