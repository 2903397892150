import placeholderImagePath from '@/assets/user-avatar_48x48.png';
import utils from '@/utils/methods';

export default {
  data() {
    return {
      imgWithErrorLoaded: new Set(),
    };
  },
  computed: {
    getPublishedDate() {
      const currentDate = new Date().toISOString();
      return utils.timeDifference(currentDate, this.commentData.created_at);
    },
    getImage: {
      get() {
        if (this.commentData) {
          // Select current image
          // const imageSrc = document.getElementById('comments-container').getAttribute('src');

          // Search if image is failed to load previously
          // To leave default placeholder instead of trying to replace again
          let imageHasError = false;

          // Check if image is in the list of failed images
          if (this.imgWithErrorLoaded) {
            imageHasError = this.imgWithErrorLoaded.has(this.commentData.profile_image_url);
          }

          // Check current image to replace with new image if not have errors
          // if (imageSrc !== authorImage && !imageHasError) {
          //   return authorImage;
          // // If image is failed when loaded change to default placeholder
          // } else 
          if (imageHasError) {
            return placeholderImagePath;
          }
          return this.commentData.profile_image_url;
        }
        return null;
      },
      set(authorImage) {
        this.imgWithErrorLoaded.add(authorImage);
      },
    },
  },
  methods: {
    // Called when some tooltip image fail to load and replace with placeholder
    setAltImg(event) {
      // this.imgWithErrorLoaded.push(event.target.src);
      const placeholderImageName = 'user-avatar_48x48.png';
      const eventImageNameSplit = event.target.src.split('/');
      const eventImageName = eventImageNameSplit[eventImageNameSplit.length - 1];

      if (eventImageName !== placeholderImageName) {
        this.imgWithErrorLoaded.add(event.target.src);
      }
    },
  },
};
