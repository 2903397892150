import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store/index';
import utils from '@/utils/methods';
// import Youtube from '../views/Youtube.vue';
import Home from '../views/Home.vue';
import How from '../views/How.vue';
import About from '../views/About.vue';
import Contact from '../views/Contact.vue';
import Pricing from '../views/Pricing.vue';
import Forms from '../views/Forms.vue';
import SignUp from '../views/SignUp.vue';
import Login from '../views/Login.vue';
import ResetPassword from '../views/ResetPassword.vue';
import Dashboard from '../views/Dashboard.vue';
import DashboardTwitter from '../views/DashboardTwitter.vue';

// const aliasLangRoutes = ['/es', '/en'];
const acceptedLanguages = ['es', 'en'];
/* const aliasRoutes = (aliasPath) => {
  const alias = [];
  aliasLangRoutes.forEach((element) => {
    alias.push(`${element}${aliasPath}`);
  });
  console.log(alias);
  return alias;
}; */

const routes = [
  {
    path: '/:lang?/',
    name: 'Home',
    component: Home,
  },
  // {
  //   path: '/:lang?/dashboard/:id',
  //   name: 'Dashboard',
  //   component: Dashboard,
  // },
  {
    path: '/:lang?/how',
    name: 'How',
    component: How,
  },
  {
    path: '/:lang?/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/:lang?/about',
    name: 'About',
    component: About,
  },
  {
    path: '/:lang?/youtube/:id',
    name: 'Youtube Video',
    component: Dashboard,
  },
  {
    path: '/:lang?/twitter/:id',
    name: 'Twitter Search',
    component: DashboardTwitter,
  },
  {
    path: '/:lang?/pricing',
    name: 'Pricing',
    component: Pricing,
  },
  {
    path: '/:lang?/forms',
    name: 'Forms',
    component: Forms,
  },
  {
    path: '/:lang?/signup',
    name: 'SignUp',
    component: SignUp,
  },
  {
    path: '/:lang?/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/:lang?/reset_password',
    name: 'Reset Password',
    component: ResetPassword,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('../views/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// set language url if some navigation dont follow current language path
router.beforeEach((to, from, next) => {
  let language = to.params.lang;
  const prevLanguage = from.params.lang;
  const matchLenguage = acceptedLanguages.find((element) => element === language);
  const matchPrevLenguage = acceptedLanguages.find((element) => element === prevLanguage);

  if (!language || !matchLenguage) {
    if (prevLanguage && matchPrevLenguage) {
      store.commit('setLanguage', prevLanguage);
      next(`/${prevLanguage}${to.path}`);
    } else {
      language = utils.getLang(navigator.language);
      store.commit('setLanguage', language);
      next(`/${language}${to.path}`);
    }    
  } else {
    store.commit('setLanguage', language);
    next();
  }
});

export default router;
