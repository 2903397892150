export default {
  es: {
    objetive: 'Objetivo',
    bebopDescription: 'Bebop cambia la manera de extraer conocimiento de miles de comentarios de una manera facil y rápida.<br>',
    problemTitle: 'El problema',
    problemDescription: `Actualmente toda la realimentación que se recibe como influencer o marca se basa
     en estadísticas numéricas. Cuantas visualizaciones has tenido, retweets, alcance, impresiones,
     tiempo de retención, ...<br>
     <br>Pero si nos paramos a pensar, <strong>la mayor parte de lo que puede aportarte valor se encuentra en los comentarios</strong>,
      lo que dice tu audiencia y tus consumidores.<br>`,
    todayTitle: 'Hoy en día',
    todayDescription: `Si quieres saber qué se está diciendo tienes que empezar a leer los comentarios 
      uno a uno sin poder saber con precisión de qué se está hablando y cuantas personas están hablando de ello.
      <br><br>Si solo tienes 100 comentarios para leer está bien, puedes leerlos uno a uno, pero si 
      tienes 1.000, 10.000 o 100.000, <strong>¿Cómo lo haces?</strong>`,
    changeTitle: 'El cambio',
    changeDescription: `Actualmente organizar y entender miles de comentarios es una tarea que requiere de leer los textos uno a uno 
      y queremos cambiar eso. Cambiar la manera en la que se consumen las opiniones y facilitar la extracción de conocimiento.
      <br><br>Cada vez más, el mundo no solo consume contenido si no que da su opinión, critica o aporta ideas.
     <strong>¡Hay que aprovecharlas!</strong>`,
  },
  en: {
    objetive: 'Goal',
    bebopDescription: 'Bebop changes the way to extract knowledge from thousands of comments in an easy and fast way.<br>',
    problemTitle: 'The problem',
    problemDescription: `Currently all the feedback you receive as an influencer or brand is based on numerical statistics. 
    How many views you have had, retweets, reach, impressions, retention time, ...
    <br><br>But if you think about it, <strong>most of what can bring you value is in the comments</strong>,
    what your audience and your consumers are saying.<br>`,
    todayTitle: 'Nowadays',
    todayDescription: `If you want to know what is being said you have to start reading the comments one by one 
    without being able to know precisely what is being talked about and how many people are talking about it.
      <br> <br> 
      If you only have 100 comments to read that's fine, you can read them one by one, but if you have 1,000, 10,000 or 100,000, 
 <strong>how do you do that?</strong>`,
    changeTitle: 'The change',
    changeDescription: `Currently organizing and understanding thousands of comments is a task that requires reading the texts 
    one by one and we want to change that. Change the way opinions are consumed and facilitate the extraction of knowledge.
    <br> <br> 
    More and more, the world is not only consuming content but also giving its opinion, criticizing or contributing ideas. 
    <strong>We have to take advantage of it!</strong>`,
  },
};
