/* eslint-disable no-param-reassign */

import Joi from 'joi';
import store from '../store/index';
import literals from '../literals/index';

const youtubeFormSchema = Joi.object({
  videoUrl: Joi.string()
    .trim()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'string.empty':
            err.message = literals.forms[store.state.lang].invalidUrlInputErrorMsg;
            break;
          case 'string.base':
            err.message = literals.forms[store.state.lang].invalidUrlInputErrorMsg;
            break;
          case 'any.required':
            err.message = literals.forms[store.state.lang].invalidUrlInputErrorMsg;
            break;
          default:
            err.message = literals.forms[store.state.lang].invalidUrlInputErrorMsg;
            break;
        }
      });
      return errors;
    }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'string.empty':
            err.message = literals.forms[store.state.lang].invalidEmailInputErrorMsg;
            break;
          case 'string.base':
            err.message = literals.forms[store.state.lang].invalidEmailInputErrorMsg;
            break;
          case 'string.email':
            err.message = literals.forms[store.state.lang].invalidEmailInputErrorMsg;
            break;
          case 'any.required':
            err.message = literals.forms[store.state.lang].invalidEmailInputErrorMsg;
            break;
          default:
            err.message = literals.forms[store.state.lang].invalidEmailInputErrorMsg;
            break;
        }
      });
      return errors;
    }),
  consentCheck: Joi.boolean().required(),
  consentCheckMarketing: Joi.boolean(),
});
export default youtubeFormSchema;
