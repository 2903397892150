export default {
  es: {
    wannaTry: '¡Quiero probar!',
    contactUs: 'Contáctanos',
    forCurious: 'Para curiosos',
    forProfessionals: 'Para profesionales',
    forAgencies: 'Para agencias y marcas',
    platformsDisponibility: 'Plataformas disponibles',
    commentsLimits: 'Límite de comentarios',
    withoutLimit: 'Sin límite',
    dayLimit: 'Límite de análisis por día',
    sentimentAnalysis: 'Análisis de sentimiento',
    automaticGroup: 'Agrupamiento automático',
    updateAndTracking: 'Actualización y seguimiento',
    personalization: 'Personalización',
  },
  en: {
    wannaTry: 'I want to try!',
    contactUs: 'Contact us',
    forCurious: 'For curious',
    forProfessionals: 'For professionals',
    forAgencies: 'For agencies and brands',
    platformsDisponibility: 'Available platforms',
    commentsLimits: 'Comment limit',
    withoutLimit: 'Unlimited',
    dayLimit: 'Analysis limit per day',
    sentimentAnalysis: 'Sentiment analysis',
    automaticGroup: 'Automatic grouping',
    updateAndTracking: 'Update and tracking',
    personalization: 'Personalization',
  },
};
