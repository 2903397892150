<template>
  <div class="col-md-4">
    <div class="card shadow">
      <img
        class="card-img-top"
        :src="previewExample.thumbnailUrl"
        :alt="previewExample.title"
        loading="lazy"
      />
      <div class="card-body">
        <div class="row pb-2">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-2">
                <img
                  class="mb-1 rounded-circle img-fluid"
                  :src="previewExample.channelThumbnailUrl"
                  :alt="previewExample.channelTitle"
                  loading="lazy"
                />
              </div>
              <div class="col-md-10">
                <h3 :title="previewExample.title" class="h6 example-title">
                  <EllipsisMultiline :text="previewExample.title"/>
                </h3>
                <p :title="previewExample.channelTitle" class="example-channel">
                  <EllipsisMultiline :text="previewExample.channelTitle" :limit="30"/>
                  <br />{{ getVisualizations }} {{ getLiterals.videoExampleComponent.comments }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <router-link :to="`${previewExample.uri}`" v-slot="{ href, navigate }" custom>
        <a :href="href" @click="navigate"
          class="btn btn-outline-primary d-flex justify-content-center 
            align-items-center flex-grow-0">{{ getLiterals.videoExampleComponent.seeMap }}
        </a>
      </router-link>
      </div>
    </div>
  </div>

</template>

<script>
import literalsMixin from '@/mixins/literals_mixin';
import EllipsisMultiline from '@/components/EllipsisMultiline.vue';

export default {
  name: 'YoutubeVideoExample',
  mixins: [literalsMixin],
  components: {
    EllipsisMultiline,
  },
  props: {
    previewExample: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getVisualizations() {
      const numberFormat = new Intl.NumberFormat('de-DE');
      if (this.previewExample.visualizations >= 1000000) {
        return `${numberFormat.format(this.previewExample.visualizations / 1000000)}M`;
      }
      return numberFormat.format(this.previewExample.visualizations);
    },
  },
};
</script>

<style lang="scss" scoped>
.example-title {
  height: 35px;
}
.example-channel {
  height: 55px;
}
</style>
