<template>
  <div class="py-5 cover align-items-center d-flex">
    <div class="container">
      <div class="row">
        <div class="px-lg-5 d-flex flex-column justify-content-center col-lg-6">
          <h1>{{getLiterals.contact.needFeedback}}</h1>
          <p class="mb-3 lead" v-html="getLiterals.contact.contactDescription"></p>
        </div>
        <div class="col-lg-6"> <img class="img-fluid d-block" 
        src="../assets/prototyping-process-amico.png"> </div>
      </div>
    </div>
  </div>
</template>

<script>
import literalsMixin from '@/mixins/literals_mixin';

export default {
  mixins: [literalsMixin],
};

</script>
