<template>
  <div id="spinner-wrapper" class="vh-100 align-items-center d-flex justify-content-center"
    v-if="isLoading">
    <LoadSpinner/>
  </div>
  <div class="my-2 mx-auto" v-show="!isLoading">
      <div class="row mx-1 mx-md-3">
        <div class="col">
          <YoutubeVideoInfo v-if="$store.state.scatterCommentsData"
            :videoData="$store.state.scatterCommentsData"/>
        </div>
        <div class="col-xl-5">
          <ul class="nav nav-pills my-2" id="dashboard-main-nav" role="tablist">
            <li class="nav-item" role="presentation">
              <a @click.prevent="loadAllCluster" href="#topic" id="topic-tab" 
                data-toggle="tab" role="tab" aria-controls="topic" 
                aria-selected="true" class="nav-link active">
                {{ getLiterals.dashboard.topicNav }}
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" @click.prevent="showMap" id="map-tab" href="#map" 
              data-toggle="tab" role="tab" aria-controls="map" aria-selected="false">
                {{ getLiterals.dashboard.mapNav }}
              </a>
            </li>
            <li class="ml-1 nav-item" v-if="!isKeywordsEmpty">
              <form v-if="searchInputKeywords.length === 0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control" 
                    id="exampleFormControlInput1" 
                    placeholder="keywords"
                    aria-label="keywords"
                    v-model="formData.searchInput">
                    <div class="input-group-append">
                      <button @click.prevent="searchKeywords" class="btn btn-primary" type="submit">
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                </div>
              </form>
              <div class="form-control h-auto px-3" v-else>
                <div 
                  class="keyword-wrapper d-inline mr-1"
                  :key="index"
                  v-for="(word, index) in searchInputKeywords"
                >
                  <span class="badge badge-primary badge-pill">
                    {{word}}
                    <a 
                      class="text-dark"
                      href=""
                      @click.prevent="removeSearchTag(word)">
                      <i class="fa fa-times"></i>
                    </a>
                  </span>
                </div>
              </div>
            </li>
          </ul>
          <div class="tab-content" id="dashboard-main-tab-content">
            <div class="tab-pane fade show active" id="topic" role="tabpanel" 
              aria-labelledby="topic-tab">
              <ul data-test-id="topics-list-container"
                class="list-group shadow py-0 my-2 overflow-y vh-70" 
                v-if="$store.state.scatterCommentsData">
                <a
                  class="text-dark comments-size"
                  href="#"
                  :key="cluster.cluster_id" 
                  v-for="cluster in orderedClustersByComments"
                >
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center"
                    @click.prevent="loadClusterComments(cluster, $event)"
                  >
                      <div class="no-event" v-if="keywordsPerCluster && !isKeywordsEmpty">
                        <span
                          class="badge badge-dark badge-pill mr-1"
                          v-for="keyword in getClusterKeywordsTitle(cluster.cluster_id)"
                          :key="keyword">
                          {{keyword}}
                        </span>
                      </div>
                      <p class="no-event" v-else>
                        <ReadMore
                          :text="getClusterTitle(cluster.cluster_name, cluster.cluster_id)"
                          :limit=250
                        />
                      </p>
                    <span class="badge badge-primary badge-pill">{{cluster.cluster_count}}</span>
                  </li>
                </a>
              </ul>
            </div>
            <div class="tab-pane fade vh-70 border rounded shadow" 
              id="map" role="tabpanel" aria-labelledby="map-tab">
              <DiagramScatterplot v-if="diagramDisplayScatter && renderDiagramComponent"
                :scatterCommentsData="diagramDisplayScatter"
                :scatterType="dataFileType"
                :searchKeywords="searchInputKeywords"/>
            </div>
          </div>
        </div>
        <div class="mt-0 col-xl-5">
          <ul class="nav nav-pills my-2" id="dashboard-complement-nav" role="tablist">
            <li class="nav-item" role="presentation">
              <a href="#charts" id="charts-tab" data-toggle="tab" role="tab" aria-controls="topic"
                aria-selected="true" class="nav-link active" @click.prevent="showCharts">
                {{ getLiterals.dashboard.chartNav }}
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a href="#comments" id="comments-tab" data-toggle="tab" role="tab"
              aria-controls="topic" aria-selected="true" class="nav-link">
                  {{ getLiterals.dashboard.commentsNav }}
              </a>
            </li>
          </ul>
          <div class="tab-content" id="dashboard-complement-tab-content">
            <div class="tab-pane fade show active" id="charts" role="tabpanel" 
              aria-labelledby="charts-tab">
              <div class="overflow-auto vh-70">
                <div id="sentiment-chart" class="border rounded shadow mx-2 my-2">
                </div>
                <div id="histogram-chart" class="border rounded shadow mx-2 my-2">
                </div>
                <div v-show="!isKeywordsEmpty" 
                  id="keywords-chart" class="border rounded shadow mx-2 my-2">
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="comments" role="tabpanel" 
              aria-labelledby="comments-tab">
              <div id="comments-container"
                class="scroll border rounded shadow bg-light py-2 vh-70">
                <YoutubeComment v-for="comment in getCommentsToDisplay()"
                  :key="comment.id"
                  :commentData="comment">
                </YoutubeComment>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import YoutubeComment from '@/components/YoutubeComment.vue';
import YoutubeVideoInfo from '@/components/YoutubeVideoInfo.vue';
import DiagramScatterplot from '@/components/DiagramScatterplot.vue';
import LoadSpinner from '@/components/LoadSpinner.vue';
import dashboardMixin from '@/mixins/dashboard_mixin';
import ReadMore from '@/components/ReadMore.vue';
import constants from '@/constants/index';

export default {
  name: 'Dashboard',
  components: {
    YoutubeComment,
    YoutubeVideoInfo,
    DiagramScatterplot,
    LoadSpinner,
    ReadMore,
  },
  mixins: [dashboardMixin],
  data() {
    return {
      // called in mixin mounted method
      dataFileType: constants.scatterType.youtube,
    };
  },
};
</script>
