export default {
  es: {
    contactUs: 'Contáctanos',
    objetive: 'Objetivo',
    tutorial: 'Tutorial',
    pricing: 'Precios',
    try: 'Pruébalo',
  },
  en: {
    contactUs: 'Contact us',
    objetive: 'Goal',
    tutorial: 'Tutorial',
    pricing: 'Pricing',
    try: 'Try it',
  },
};
