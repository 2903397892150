<template>
  <div id="sign-up-container text-dark">
    <div class="m-4 shadow p-3">
      <form
        id="form-login"
        data-test-id="form-login"
        novalidate
      >
        <div class="form-group">
          <label>Email</label>
          <p
            class="form-errors text-left"
            v-if="formErrors.email"
            data-test-id="input-error-text-login-email">
            {{ formErrors.email }}
          </p>
          <input
            type="email"
            class="form-control"
            name="login-email"
            autocomplete="on"
            :class="emailInputValidation"
            placeholder="email"
            v-model="formData.email"
            id="login-email"
            aria-describedby="login user email"
            data-test-id="login-email"
            required
          />
          <div class="form-group">
            <div class="form-group pt-2">
              <label>{{ getLiterals.loginComponent.password }}</label>
              <p
                class="form-errors text-left"
                v-if="formErrors.password"
                data-test-id="input-error-login-password">
                {{ formErrors.password }}
              </p>
              <input
                type="password"
                class="form-control"
                name="input-login-password"
                autocomplete="on"
                :class="passwordInputValidation"
                :placeholder="getLiterals.loginComponent.password"
                v-model="formData.password"
                id="input-login-password"
                aria-describedby="login user password"
                data-test-id="input-login-password"
                required
              />
            </div>
          </div>
        </div>
        <div class="text-md-center">
          <router-link
            :to="{name: 'Reset Password'}"
            v-slot="{ href, navigate }"
            custom
          >
            <a :href="href" @click="navigate">
              <p>{{ getLiterals.loginComponent.forgotPassword }}</p>
            </a>
          </router-link>
          <button
            type="submit" class="btn btn-outline-primary mt-3" href="#"
            data-toggle="modal"
            @click.prevent="loginUser"
            :disabled="isLoading"
            id="button-login"
            data-test-id="button-login"
          >
          {{ getLiterals.loginComponent.login }}
        </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import literalsMixin from '@/mixins/literals_mixin';
import loginFormSchema from '../schemas/login_form_joi';
import literals from '../literals/index';
import constants from '../constants/index';

export default {
  name: 'LoginForm',
  mixins: [literalsMixin],
  data() {
    return {
      formHasErrors: false,
      formErrors: {
        email: '',
        password: '',
      },
      formData: {
        email: '',
        password: '',
      },
      isLoading: false,
    };
  },
  computed: {
    emailInputValidation() {
      if (this.formHasErrors) {
        return this.formErrors.email ? 'is-invalid' : 'is-valid';
      }
      return '';
    },
    passwordInputValidation() {
      if (this.formHasErrors) {
        return this.formErrors.password ? 'is-invalid' : 'is-valid';
      }
      return '';
    },
    enableFormVerificationCode: {
      get() {
        return this.$store.state.enableFormVerificationCode;
      },
      set(enabled) {
        this.$store.commit('setEnableFormVerificationCode', enabled);
      },
    },
  },
  mounted() {
    if (this.$store.state.loginEmail) {
      this.formData.email = this.$store.state.loginEmail;
      this.$store.commit('setLoginEmail', null);
    }
  },
  methods: {
    async loginUser() {
      this.formErrors.email = '';
      this.formErrors.password = '';

      const validation = loginFormSchema.validate(this.formData, {
        abortEarly: false,
      });
      if (!validation.error) {
        try {
          this.isLoading = true;
          const data = await Auth.signIn(this.formData.email, this.formData.password);
          console.log(data);
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          if (error.code) {
            switch (error.code) {
              case 'NotAuthorizedException':
                this.$store.commit('addToastData', {
                  title: literals.errors[this.$store.state.lang].wrongDataLoginTitle,
                  body: literals.errors[this.$store.state.lang].wrongDataLoginBody,
                  category: constants.toastStatus.error,
                });
                break;
              case 'UserNotConfirmedException':
                this.enableFormVerificationCode = true;
                Auth.resendSignUp(this.formData.email);
                this.$store.commit('addToastData', {
                  title: literals.errors[this.$store.state.lang].userNotConfirmedExceptionTitle,
                  body: literals.errors[this.$store.state.lang].userNotConfirmedExceptionBody,
                  category: constants.toastStatus.error,
                });
                this.$store.commit('setLoginEmail', this.formData.email);
                this.$router.push('/signup');
                break;
              default:
                this.$store.commit('addToastData', {
                  title: literals.errors[this.$store.state.lang].unkownErrorLoginTitle,
                  body: literals.errors[this.$store.state.lang].unkownErrorLoginBody,
                  category: constants.toastStatus.error,
                });
                break;
            }
          } else {
            this.$store.commit('addToastData', {
              title: literals.errors[this.$store.state.lang].unkownErrorLoginTitle,
              body: literals.errors[this.$store.state.lang].unkownErrorLoginBody,
              category: constants.toastStatus.error,
            });
          }
        }
      } else {
        this.formHasErrors = true;
        this.checkFormErrors(validation.error.details);
      }
    },
    checkFormErrors(errors) {
      const self = this;
      errors.forEach((error) => {
        switch (error.context.key) {
          case 'password':
            self.formErrors.password = error.message;
            break;
          case 'email':
            self.formErrors.email = error.message;
            break;
          default:
            break;
        }
      });
    },
  },
};
</script>
