<template>
  <div class="my-5 d-flex justify-content-center align-items-center">
    <div class="col col-md-4">
      <SignUpForm/>
    </div>
  </div>
</template>

<script>
import SignUpForm from '@/components/SignUpForm.vue';

export default {
  name: 'SignUp',
  components: {
    SignUpForm,
  },
};
</script>
