import dashboard from './dashboard';
import forms from './forms';
import errors from './errors';
import menu from './menu';
import modal from './modal';
import home from './home';
import about from './about';
import how from './how';
import contact from './contact';
import signUpComponent from './signUpComponent';
import loginComponent from './loginComponent';
import resetPasswordComponent from './resetPasswordComponent';
import videoFormComponent from './videoFormComponent';
import videoExampleComponent from './videoExampleComponent';
import youtubeVideoInfoComponent from './youtubeVideoInfoComponent';
import pricing from './pricing';
import readMoreComponent from './readMoreComponent';

export default {
  dashboard,
  forms,
  errors,
  menu,
  modal,
  home,
  about,
  how,
  contact,
  videoFormComponent,
  videoExampleComponent,
  pricing,
  youtubeVideoInfoComponent,
  readMoreComponent,
  signUpComponent,
  resetPasswordComponent,
  loginComponent,
};
