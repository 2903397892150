<template>
{{readMore}}
<a href="" v-if="showReadMore" @click.prevent="showMore">{{showMoreText}}</a>
<a href="" v-if="showReadLess" @click.prevent="showLess">{{showLessText}}</a>
</template>

<script>
import literals from '@/literals/index';

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    limit: {
      type: Number,
      default: 50,
    },
  },
  mounted() {
    if (this.getTextLength >= this.limit) {
      this.showReadMore = true;
    }
  },
  data() {
    return {
      showReadMore: false,
      showReadLess: false,
      displayText: this.text,
    };
  },
  computed: {
    readMore: {
      get() {
        if (this.getTextLength >= this.limit && this.showReadMore) {
          return this.getLessText;
        }
        return this.displayText;
      },
      set(text) {
        this.displayText = text;
      },
    },
    getLessText() {
      let textDisplay = this.text.substring(0, this.limit);
      textDisplay += '...';
      return textDisplay;
    },
    getTextLength() {
      return this.text.length;
    },
    showMoreText() {
      return literals.readMoreComponent[this.$store.state.lang].showMore;
    },
    showLessText() {
      return literals.readMoreComponent[this.$store.state.lang].showLess;
    },
  },
  methods: {
    showMore() {
      this.showReadMore = false;
      this.showReadLess = true;
      this.readMore = this.text;
    },
    showLess() {
      this.showReadMore = true;
      this.showReadLess = false;
      this.readMore = this.getLessText;
    },
  },
};
</script>
