<template>
{{ellipsisText}}
</template>

<script>

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    limit: {
      type: Number,
      default: 50,
    },
  },
  mounted() {
    if (this.getTextLength >= this.limit) {
      this.showReadMore = true;
    }
  },
  data() {
    return {
      displayText: this.text,
    };
  },
  computed: {
    ellipsisText: {
      get() {
        if (this.getTextLength >= this.limit) {
          return `${this.getLessText}...`;
        }
        return this.displayText;
      },
    },
    getLessText() {
      return this.text.substring(0, this.limit);
    },
    getTextLength() {
      return this.text.length;
    },
  },
};
</script>
