import axios from 'axios';

export default {
  getLocalProcessedVideo(id, type) {
    const url = `${window.location.origin}/${type}/${id}.json`;
    return axios.get(url, {
      headers: { 'Accept-Language': navigator.language },
    });
  },
  getProcessedVideo(id, type) {
    const url = `${process.env.VUE_APP_MEDIA_DATA_API_URL}/file/${id}?type=${type}`;
    return axios.get(url, {
      headers: { 'Accept-Language': navigator.language },
    });
  },
  getProcessedFile(url) {
    return axios.get(url);
  },
};
