<template>
  <div id="spinner-wrapper" class="align-items-center d-flex justify-content-center"
    v-if="isLoading">
    <LoadSpinner/>
  </div>
  <div v-show="!isLoading" id="id-diagram" class="diagram">
    <div id="ttm">
      <div class="shadow-sm" id="diagram-container">
        <div>
          <svg ref="scrollRef" id="svg-scatterplot" data-test-id="svg-scatterplot"/>
          <div id="diagram-tooltip-wrapper" v-show="Object.keys(tooltipData).length > 0 ">
            <DiagramTooltip v-if="scatterType === 'youtube'" :commentData="tooltipData"/>
            <DiagramTooltipTwitter v-if="scatterType === 'twitter'" :commentData="tooltipData"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import placeholderImagePath from '@/assets/user-avatar_48x48.png';
import DiagramTooltip from '@/components/DiagramTooltip.vue';
import DiagramTooltipTwitter from '@/components/DiagramTooltipTwitter.vue';
import LoadSpinner from '@/components/LoadSpinner.vue';

export default {
  name: 'DiagramScatterplot',
  components: {
    DiagramTooltip,
    LoadSpinner,
    DiagramTooltipTwitter,
  },
  props: {
    scatterCommentsData: {
      type: Object,
      required: true,
    },
    scatterType: {
      type: String,
      required: true,
    },
    searchKeywords: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: false,
      imgWithErrorLoaded: new Set(),
      tooltipData: {},
      brushData: null, // Comments selected after brush
      // Check if data to show is filtered in base of selection data
      // Instead of total data
      isSelectionData: false,
      isBrushClearEvent: false,
      navAllActive: 'active',
      navGroupActive: '',
    };
  },
  async mounted() {
    this.drawDiagram(this.scatterCommentsData.comments_data);
    // Resize diagram is window is resized
    const windowResizeEvent = () => {
      if (this.scatterCommentsData) {
        this.drawDiagram(this.scatterCommentsData.comments_data);
      }
    };
    window.addEventListener('resize', windowResizeEvent);
  },
  watch: {
    searchKeywords() {
      this.$store.commit('addScatterCommentsFilterData', this.scatterCommentsData.comments_data);
      this.drawDiagram(this.scatterCommentsData.comments_data);
    },
    // Draw diagram when data is changed
    scatterCommentsData(newData) {
      if (newData) {
        this.drawDiagram(newData.comments_data);
      }
    },
  },
  methods: {
    // this method is called from the parent component when data is filled and scroll to diagram
    // scrollToElement() {
    //   document.getElementById('svg-scatterplot')
    //    .scrollIntoView({ behavior: 'smooth', passive: true });
    // },

    // Update tooltip image to default placeholder image
    changeToPlaceHolderImage(d3Element) {
      d3Element.attr('src', placeholderImagePath);
    },
    // Called when some tooltip image fail to load and replace with placeholder
    setAltImg(event) {
      // this.imgWithErrorLoaded.push(event.target.src);
      const placeholderImageName = 'user-avatar_48x48.png';
      const eventImageNameSplit = event.target.src.split('/');
      const eventImageName = eventImageNameSplit[eventImageNameSplit.length - 1];

      if (eventImageName !== placeholderImageName) {
        this.imgWithErrorLoaded.add(event.target.src);
        event.target.src = placeholderImagePath;
      }
    },
    drawDiagram(data) {
      const self = this;
      const elementWidth = document.getElementById('svg-scatterplot').clientWidth;
      const elementHeight = document.getElementById('svg-scatterplot').clientHeight;
      const width = elementWidth;
      const height = elementHeight;
      this.isLoading = true;
      setTimeout(() => {
        // TODO check if need margin when some big point is draw in border areas
        /* const margin = {
          top: 10, right: 10, bottom: 50, left: 50,
        }; */

        const svg = d3.select('#svg-scatterplot');

        // Clean previous axis data
        svg.selectAll('g').remove();
        svg.append('g');
  
        // A function that set idleTimeOut to null
        let idleTimeout;

        function idled() { idleTimeout = null; }

        data.forEach((d) => {
          /* eslint no-param-reassign: "off" */
          d.c0 = +d.c0;
          d.c1 = +d.c1;
        });

        const c0min = d3.min(data, (d) => d.c0);
        const c0max = d3.max(data, (d) => d.c0); 
        const c1min = d3.min(data, (d) => d.c1); 
        const c1max = d3.max(data, (d) => d.c1); 

        // const maxLikes = d3.max(data, (d) => d.likeCount);

        // Add X axis
        const x = d3.scaleLinear()
          .domain([c0min - 10, c0max + 10])
          .range([0, width]);

        const xAxis = svg.append('g')
          .attr('transform', `translate(0, ${height})`)
          .call(d3.axisBottom(x))
          .style('opacity', 0);

        // Add Y axis
        const y = d3.scaleLinear()
          .domain([c1min - 10, c1max + 10])
          .range([height, 0]);

        const yAxis = svg.append('g')
          .attr('transform', `translate(0, ${width})`)
          .call(d3.axisLeft(y))
          .style('opacity', 0);

        // const radio = d3.scaleLinear()
        // .domain([5, 10]);
        // .range([3, 100]);

        const brush = d3.brush() // Add the brush feature using the d3.brush function
          // initialise the brush area:
          // start at 0,0 and finishes at width,height: it means I select the whole graph area
          .extent([[0, 0], [width, height]]); 

        function updateChart() {
          if (!self.isBrushClearEvent) {
            const extent = d3.event.selection;
            let c0MinAux = c0min;
            let c0MaxAux = c0max;
            let c1MinAux = c1min;
            let c1MaxAux = c1max;

            // If no selection, back to initial coordinate. Otherwise, update X axis domain
            if (!extent) {
              if (!idleTimeout) {
                idleTimeout = setTimeout(idled, 350); // This allows to wait a little bit
                return idleTimeout;
              }
              x.domain([c0min, c0max]);
              y.domain([c1min, c1max]);
            } else {
              c0MinAux = x.invert(extent[0][0]);
              c0MaxAux = x.invert(extent[1][0]);
              c1MinAux = y.invert(extent[1][1]);
              c1MaxAux = y.invert(extent[0][1]);

              x.domain([x.invert(extent[0][0]), x.invert(extent[1][0])]);
              y.domain([y.invert(extent[1][1]), y.invert(extent[0][1])]);

              // Call brush clear cause to call again updateChart method
              // Add validation to execute only once
              self.isBrushClearEvent = true;
              svg.select('.brush').call(brush.move, null); // This remove the grey brush area as soon as the selection has been done
            }

            // Update axis and circle position
            xAxis.transition().duration(500).call(d3.axisBottom(x));
            yAxis.transition().duration(500).call(d3.axisLeft(y));

            svg
              .selectAll('circle')
              .transition().duration(1000)
              .attr('cx', (d) => x(d.c0))
              .attr('cy', (d) => y(d.c1));

            const filterComments = data.filter(
              (d) => d.c0 > c0MinAux && d.c0 < c0MaxAux && d.c1 > c1MinAux && d.c1 < c1MaxAux,
            );

            /*
            * When use double click to reset brush area
            * filterComments dont return all the diagram comments
            * to calculate when double click is performed and reset the diagram data
            * we are calculating the difference between
            * current filterComments when brush event is called
            * and the total comments, if brush selection is apply
            * and the filterComments is close to total number of totalComments
            *  then reset diagram variables
            */
            const commentsPercentage = self.$store.state.scatterCommentsData.comments_data.length
              / filterComments.length;
            // console.log(self.$store.state.scatterCommentsFilterData.length);
            // call when double click after brush to reset
            if ((commentsPercentage > 1 && commentsPercentage < 1.020)
              && self.isSelectionData) {
              self.isSelectionData = false;
              self.$store.commit('addScatterCommentsFilterData', self.$store.state.scatterCommentsData.comments_data);
              self.drawDiagram(self.$store.state.scatterCommentsData.comments_data);
            } else if (!self.isSelectionData) { // call when is brush apply first time after reset
              self.isSelectionData = true;
            }

            if (self.isSelectionData) {
              self.$store.commit('addScatterCommentsFilterData', filterComments);
            }

            if (self.isBrushClearEvent) {
              self.isBrushClearEvent = false;
            }
          }
          return idleTimeout;
        }

        brush.on('end', updateChart);

        let tooltip = d3.select('#diagram-tooltip-wrapper');

        // Three function that change the tooltip when user hover / move / leave a cell
        const mouseover = (d) => {
          self.tooltipData = d;
        };

        const mousemove = function () {
          // let yOffset = 0;
          // if (window.screen.width <= 768) {
          //   yOffset = -20;
          // } else if (window.screen.width <= 992) {
          //   yOffset = -15;
          // } else if (window.screen.width <= 1200) {
          //   yOffset = -10;
          // }
          // console.log(d3.event.toElement.cx.animVal.value);
          tooltip = d3.select('#diagram-tooltip-wrapper');
          let circleOffset = 0;
          // Show tooltip more to the rigth base on circle width
          if (d3.event.target.getBoundingClientRect().width >= 36) {
            circleOffset = 12;
          } else if (d3.event.target.getBoundingClientRect().width <= 6) {
            circleOffset = -4;
          }
          const left = d3.event.target.getBoundingClientRect().width 
            + d3.event.target.cx.animVal.value - circleOffset; 
          // Get the height calculating the distance with the tooltip triangle position
          // to match in center circle
          const top = d3.event.target.cy.animVal.value - tooltip.node().clientHeight;
          tooltip
            .style('left', `${left}px`)
            .style('top', `${top - 30}px`);
          // .style('top', `${d3.event.toElement.cy.animVal.value}px`);
        };

        const mouseleave = () => {
          self.tooltipData = {};
        };

        svg.append('g')
          .attr('class', 'brush')
          .call(brush);

        const getColorBySentiment = (d) => {
          let colorClass = 'sentiment-neutral';
          if (d.sentiment === 'negative') {
            colorClass = 'sentiment-negative';
          } else if (d.sentiment === 'positive') {
            colorClass = 'sentiment-positive';
          }
          return colorClass;
        };

        const getColorByGroup = (d) => {
          let color = 'fill:grey';
          if (this.searchKeywords && this.searchKeywords.length > 0) {
            this.searchKeywords.some((keywordSearch) => {
              const keywordMatch = d.keywords
                .find((keyword) => keyword.toLowerCase() === keywordSearch.toLowerCase());

              if (keywordMatch) {
                color = 'fill:#fc5185';
                return true;
              }

              return false;
            });
          } else {
            self.scatterCommentsData.clusters.forEach((cluster) => {
              if (d.cluster_id === cluster.cluster_id && d.cluster_id !== -1) {
                // multiply to 255 to get value between 0 and 255
                const red = cluster.cluster_color[0] * 255;
                const green = cluster.cluster_color[1] * 255;
                const blue = cluster.cluster_color[2] * 255;
                color = `fill:rgb(${red},${green},${blue});`;
              }
            });
          }
          // draw grey when cluster is -1 (no group)
          return color;
        };

        svg.append('g')
          .attr('id', 'diagram-circles-wrapper')
          .selectAll('dot')
          .data(data) // the .filter part is just to keep a few dots on the chart, not all of them
          .enter()
          .append('circle')
          .attr('cx', (d) => x(d.c0))
          .attr('cy', (d) => y(d.c1))
          // .attr('r', (d) => radio(d.like_count) * 15 + 3)
          .attr('r', 3)
          .attr('class', (d) => `${getColorBySentiment(d)} sentiment-circle`)
          .attr('style', (d) => getColorByGroup(d))
          .on('mouseover', mouseover)
          .on('mousemove', mousemove)
          .on('mouseleave', mouseleave);

        self.isLoading = false;
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
#spinner-wrapper {
  height: 70vh;
}

#ttm {
  position: relative;
}

#svg-scatterplot {
  width: 100%;
  height: 70vh;
}

svg{
    background-color: white;
    width: auto;
    height: auto;
}

.icon-search {
    color:white;
    background-color:#191919;
}

#diagram-tooltip-wrapper {
  position: absolute;
  display: inline-block;
  max-width: 540px;
  width: 100%;
  z-index: 1000;
}
</style>
