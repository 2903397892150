<template>
  <div class="card shadow my-2">
    <div class="card-body">
      <div class="row pb-2">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <h6>{{searchTitle}}</h6>
              <p>
                <strong>
                  {{ getVisualizations }} {{ getLiterals.youtubeVideoInfoComponent.visualizations }}
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import literalsMixin from '@/mixins/literals_mixin';

export default {
  mixins: [literalsMixin],
  props: {
    searchTitle: {
      type: String,
      required: true,
    },
    totalResults: {
      type: Number,
      required: true,
    },
  },
  computed: {
    getVisualizations() {
      const numberFormat = new Intl.NumberFormat('de-DE');
      if (this.totalResults >= 1000000) {
        return `${numberFormat.format(this.totalResults / 1000000)}M`;
      }
      return numberFormat.format(this.totalResults);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
