<template>
  <div class="card shadow my-2">
      <img
        id="video-thumbnail"
        class="card-img-top"
        loading="lazy"
        :src="getVideoInfo.thumbnails.url"
        :alt="getVideoInfo.title">
    <div class="card-body">
      <div class="row pb-2">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <h6>{{getVideoInfo.title}}</h6>
              <p class="mb-2">{{ getchannelInfo.title }}</p>
              <p>
                <strong>
                  {{ getVisualizations }} {{ getLiterals.youtubeVideoInfoComponent.visualizations }}
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import literalsMixin from '@/mixins/literals_mixin';

export default {
  mixins: [literalsMixin],
  props: {
    videoData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getVisualizations() {
      const numberFormat = new Intl.NumberFormat('de-DE');
      if (this.getCommentsData.length >= 1000000) {
        return `${numberFormat.format(this.getCommentsData.length / 1000000)}M`;
      }
      return numberFormat.format(this.getCommentsData.length);
    },
    getVideoInfo() {
      const { videoInfo } = this.videoData;
      const { thumbnails } = videoInfo;

      if (thumbnails.maxres) {
        videoInfo.thumbnails.url = thumbnails.maxres.url;
        videoInfo.thumbnails.width = thumbnails.maxres.width;
        videoInfo.thumbnails.height = thumbnails.maxres.height;
      } else if (thumbnails.medium) {
        videoInfo.thumbnails.url = thumbnails.medium.url;
        videoInfo.thumbnails.width = thumbnails.medium.width;
        videoInfo.thumbnails.height = thumbnails.medium.height;
      } else if (thumbnails.standard) {
        videoInfo.thumbnails.url = thumbnails.standard.url;
        videoInfo.thumbnails.width = thumbnails.standard.width;
        videoInfo.thumbnails.height = thumbnails.standard.height;
      } else {
        videoInfo.thumbnails.url = thumbnails.default.url;
        videoInfo.thumbnails.width = thumbnails.default.width;
        videoInfo.thumbnails.height = thumbnails.default.height;
      }

      return videoInfo;   
    },
    getchannelInfo() {
      return this.videoData.channelInfo;
    },
    getCommentsData() {
      return this.videoData.comments_data;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
