export default {
  es: {
    needFeedback: '¡Necesitamos tu feedback!',
    contactDescription: `Este proyecto nace con la idea de cambiar la forma en la que consumes el feedback de tu audiencia, pero nuestra audiencia eres tú.<br>
    <br>Con tus opiniones y sugerencias mejoraremos Bebop para crear una herramienta perfecta para ti.<br>
    <br>¡Mándanos un mail a <a href="mailto:info@bebop.wtf"><strong>info@bebop.wtf</strong></a> si quieres aportar a esta idea!`,
  },
  en: {
    needFeedback: 'We need your feedback!',
    contactDescription: `This project was born with the idea of changing the way you consume the feedback from your audience, but our audience is you.<br>
      <br> With your opinions and suggestions we will improve Bebop to create a perfect tool for you. <br>
      <br> Send us an email to <a href="mailto:info@bebop.wtf"><strong>info@bebop.wtf</strong></a> if you want to contribute to this idea!`,
  },
};
