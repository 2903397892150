import { createApp } from 'vue';
// eslint-disable-next-line no-unused-vars
import jquery from 'jquery';
// eslint-disable-next-line no-unused-vars
import bootstrap from 'bootstrap';
// eslint-disable-next-line no-unused-vars
import store from './store';
import App from './App.vue';
import router from './router';

// bootstrap vue is only available yet in vue 2
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// window.$ = require('jquery');

// Import Bootstrap an BootstrapVue CSS files (order is important,
// bootstrap with only css work, but javascript component is not supported yet)
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css';

createApp(App)
  .use(store)
  .use(router)
  // .use(VueApexCharts)
  // .use(jquery)
  // .use(bootstrap)
//  .use(BootstrapVue)
//  .use(IconsPlugin)
  .mount('#app');
