<template>
  <nav 
    data-test-id="menu-nav"
    class="navbar navbar-expand-md navbar-light sticky-top shadow bg-light">
    <div class="container">
      <router-link
          :to="{name: 'Home'}"
          v-slot="{ href, navigate }"
          custom>
          <a class="navbar-brand" :href="href" @click="navigate">
            <i class="fa d-inline fa-lg fa-stop-circle mr-1"></i>
            <b>Bebop</b>
          </a>
      </router-link>
      <button class="navbar-toggler navbar-toggler-right border-0"
        type="button" data-toggle="collapse" data-target="#navbar">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbar">
        <ul class="navbar-nav ml-auto">
          <router-link
            to="/about"
            v-slot="{ href, navigate, isActive }"
            custom>
            <li :class="[isActive && 'active','nav-item']">
              <a
                data-test-id="about-nav-button"
                class="nav-link"
                :href="href"
                @click="navigate">
                  {{ getLiterals.menu.objetive }}
              </a>
            </li>
          </router-link>
          <router-link
            to="/pricing"
            v-slot="{ href, navigate, isActive }"
            custom>
            <li :class="[isActive && 'active','nav-item']">
              <a 
                data-test-id="pricing-nav-button"
                class="nav-link"
                :href="href"
                @click="navigate">
                {{ getLiterals.menu.pricing }}
              </a>
            </li>
          </router-link>
          <!-- <router-link
            to="/how"
            v-slot="{ href, navigate, isActive }"
            custom>
            <li :class="[isActive && 'active','nav-item']">
              <a 
                data-test-id="how-nav-button"
                class="nav-link" 
                :href="href"
                @click="navigate">
                  {{menuTutorialText}}
              </a>
            </li>
          </router-link> -->
          <router-link
            to="/forms"
            v-slot="{ href, navigate, isActive }"
            custom>
            <li :class="[isActive && 'active','nav-item']">
              <a 
                data-test-id="forms-nav-button"
                class="nav-link" 
                :href="href"
                @click="navigate">
                {{ getLiterals.menu.try }}
              </a>
            </li>
          </router-link>
        </ul>
        <router-link
          to="/contact"
          v-slot="{ href, navigate}" custom>
          <a
            data-test-id="contact-nav-button"
            class="btn navbar-btn ml-md-2 btn-primary text-light"
            :href="href"
            @click="navigate">
            {{ getLiterals.menu.contactUs }}
          </a>
        </router-link>
      </div>
    </div>
  </nav>
  <div aria-live="polite" aria-atomic="true"
    class="toast-wrapper fixed-top mt-5" v-show="$store.state.toastData.length> 0">
    <div class="flex-column align-items-center d-flex justify-content-center">
      <transition-group name="fade" @after-enter="afterEnter"
        @before-leave="beforeLeave" :appear="true" mode="out-in">
        <ToastTemp v-for="toast in this.$store.state.toastData"
            :key="toast.id"
            :toastData="toast"
            :toastCategory="toast.category"
        ></ToastTemp>
      </transition-group>
    </div>
  </div>

  <!-- Start modal -->
  <div class="modal" id="exampleModal" tabindex="-1" v-if="$store.state.modalMessage">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-html="$store.state.modalMessage.title"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-html="$store.state.modalMessage.body"></div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" 
            data-test-id="button-close-modal">
            {{ getLiterals.modal.closeOption }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End modal -->

  <router-view/>
  <footer>
    <div class="py-3">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <i class="fa fa-rocket" aria-hidden="true"></i> 
            <span class="mb-0"> 2020-2021 Bebop</span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { Amplify, Auth } from 'aws-amplify';
// import jquery from 'jquery';
import ToastTemp from '@/components/ToastTemp.vue';
// import statusApi from '@/api/status';
import constants from '@/constants/index';
import literals from '@/literals/index';
import literalsMixin from '@/mixins/literals_mixin';

Amplify.configure({
  Auth: {
    region: process.env.VUE_APP_COGNITO_REGION,
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
});

Auth.configure();

export default {
  components: {
    ToastTemp,
  },
  mixins: [literalsMixin],
  async mounted() {
    try {
      if (!this.$store.state.statusAlreadyReceived) {
        this.$store.commit('changeStatusReceived', true);
        const self = this;

        setTimeout(() => {
          this.$store.commit('addToastData', {
            title: self.getLiterals.modal.webDiscontinuedTitle,
            body: self.getLiterals.modal.webDiscontinuedMessage,
            category: constants.toastStatus.info,
            time: constants.timers.toast.large,
          });
        }, 1000);

        // const appStatus = await statusApi.getAppStatus();

        /* if (appStatus.data.notifications && appStatus.data.notifications.length > 0) {
          appStatus.data.notifications.forEach((element) => {
            self.$store.commit('addToastData', {
              title: element.title,
              body: element.body,
              category: constants.toastStatus.info,
              time: element.time,
            });
          });
        }
        if (appStatus.data.importantMessage) {
          await self.$store.commit('changeModalMessage', appStatus.data.importantMessage);
          jquery('#exampleModal').on('hide.bs.modal', () => {
            self.$store.commit('changeModalMessage', null);
          });
          jquery('#exampleModal').modal('show');
        } */
      }
    } catch (err) {
      this.$store.commit('addToastData', {
        title: literals.errors[this.$store.state.lang].ErrorAPIStatusTitle,
        body: literals.errors[this.$store.state.lang].ErrorAPIStatusBody,
        category: constants.toastStatus.warning,
        time: constants.timers.toast.large,
      });
    }
  },
  computed: {
    isDarkTheme() {
      return this.$store.state.darkTheme;
    },
  },
  methods: {
    afterEnter(el) {
      // eslint-disable-next-line no-param-reassign
      el.style.opacity = 1;
    },
    beforeLeave(el) {
      // eslint-disable-next-line no-param-reassign
      el.style.opacity = null;
    },
  },
};
</script>
<style lang="scss">
@use "sass:map";
@use './scss/main' as main;

$sentiment-positive-color: #a8e6cf;
$sentiment-neutral-color: grey;
$sentiment-negative-color: #ff8b94;

p > strong {
  font-weight: bold;
}

.comments-size {
  font-size: 14px;
}

.vh-90 {
  height: 90vh !important;
}

.vh-70 {
  height: 70vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.scroll {
  overflow-x: hidden;
  overflow-y: auto;
}

.fade-enter-active {
  transition: opacity 0.5s;
}

.fade-leave-active {
  transition: opacity 1.5s;
}

.fade-leave-from {
  opacity: 1;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.toast-wrapper {
  height: 200px;
  z-index: 1049;
}

.sentiment-circle {
  opacity : 0.5;
  stroke: none;
}

.sentiment-positive {
  fill: $sentiment-positive-color;
}

.sentiment-neutral {
  fill: $sentiment-neutral-color;
}

.sentiment-negative {
  fill: $sentiment-negative-color;
}

.sentiment-background {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.sentiment-background-positive {
  background-color: $sentiment-positive-color;
}

.sentiment-background-neutral {
  background-color: $sentiment-neutral-color;
}

.sentiment-background-negative {
  background-color: $sentiment-negative-color;
}

.form-errors {
  color: map.get(main.$theme-colors, "danger");
}

.video-data-wrapper {
  z-index: 10;
}

.overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
}

.tooltip-diagram {
    background-color: white;;
    border: none;
    padding: 10px;
    max-width: 560px;
    text-align: left;
    color: black;
    border-radius: px;
    border: 1px solid black;
    font-weight:400;
    position: absolute;
    opacity: 1;
}

// Add border triangle in left side of tooltip
.tooltip-diagram:after{
    content:'';
    width:0;
    height:0;
    position:absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right:8px solid white;
    left:-8px;
    top:20px;
}

// Add border triangle in left side of tooltip
.tooltip-diagram:before {
    content:'';
    width:0;
    height:0;
    position:absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right:8px solid black;
    left:-9px;
    top:20px;
}

.tooltip-diagram-container {
  padding: 0px;
}

.tooltip-thumbnail-container {
  max-width: 60px;
}

#tooltip-author-thumbnail {
  width: 48px;
  height: 48px;
}

#tooltip-author-thumbnail > img{
  border-radius: 50%;
}

#tooltip-main{
  max-width: 540px;
  padding-right: 10px;
}

#tooltip-header{
  padding-bottom: 2px;
  font-size: 14px;
}

#tooltip-content{
    font-size: 15px;
    width: 100%;
    word-wrap: break-word;
}

#tooltip-likes{
  font-size: 12px;
  color: grey;
}

.list-group-item.active {
  background-color: map.get(main.$theme-colors, "secondary");
}

.no-event {
  pointer-events: none;
}
</style>
