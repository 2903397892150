<template>
  <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header" v-show="toastData.title">
      <div class="rounded mr-2 message-status" :class="getToastStatusClass"></div>
      <strong 
        data-test-id="toast-title" class="mr-auto">{{ toastData.title }}
      </strong>
      <!-- <small>11 mins ago</small> -->
      <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close"
        @click.prevent="closeToast">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div 
      data-test-id="toast-body" 
      class="toast-body" 
      v-show="toastData.body" 
      v-html="toastData.body"
    >
    </div>
  </div>
</template>

<script>
import constants from '../constants/index';

export default {
  name: 'ToastTemp',
  props: {
    toastData: {
      type: Object,
      required: true,
    },
    toastCategory: {
      type: String,
      default: constants.toastStatus.successed,
    },
  },
  mounted() {
    let toastRemoveTime = constants.timers.toast.default;

    if (this.toastData.time) {
      toastRemoveTime = this.toastData.time;
    }

    if (toastRemoveTime >= 0) {
      setTimeout(() => {
        this.$store.commit('removeToastData', this.toastData);
      }, toastRemoveTime);
    }
  },
  computed: {
    getToastStatusClass() {
      let status = 'bg-danger';
      if (this.toastCategory === constants.toastStatus.successed) {
        status = 'bg-success';
      } else if (this.toastCategory === constants.toastStatus.warning) {
        status = 'bg-warning';
      } else if (this.toastCategory === constants.toastStatus.info) {
        status = 'bg-info';
      }
      return status;
    },
  },
  methods: {
    closeToast() {
      this.$store.commit('removeToastData', this.toastData);
    },
  },
};
</script>

<style lang="scss">
.message-status {
  width: 20px;
  height: 20px;
}

.toast{
  flex-basis: auto !important;
}
</style>
