<template>
  <div class="my-5 d-flex justify-content-center align-items-center">
    <div class="col col-md-4">
      <ResetPasswordForm/>
    </div>
  </div>
</template>

<script>
import ResetPasswordForm from '@/components/ResetPasswordForm.vue';

export default {
  name: 'ResetPassword',
  components: {
    ResetPasswordForm,
  },
};
</script>
