<template>
  <div :style="{ width: width, height: height}"
    class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '6rem',
    },
    height: {
      type: String,
      default: '6rem',
    },
  },
};
</script>
