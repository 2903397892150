export default {
  es: {
    password: 'Contraseña',
    forgotPassword: '¿Has olvidado la contraseña?',
    login: 'Iniciar sesión',
  },
  en: {
    password: 'Password',
    forgotPassword: 'Have you forgotten the password?',
    login: 'Log in',
  },
};
