export default {
  es: {
    UknownAPIErrorTitle: 'Error desconocido',
    UknownAPIErrorMessage: '¡Ha ocurrido un error que no nos esperábamos, nos pondremos manos a la obra para arreglarlo!',
    UknownAPIConsentErrorTitle: 'Error desconocido al procesar consentimiento',
    UknownAPIProcessVideoErrorTitle: 'Error desconocido al procesar vídeo',
    ErrorAPIStatusTitle: 'Error al obtener estado de la app',
    ErrorAPIStatusBody: 'Estamos experimentando problemas en la aplicación, es posible que algunas funcionalidades no respondan correctamente',
    errorGetVideoApiTitle: 'Error obtener vídeo',
    errorGetVideoApiBody: 'No hemos podido obtener el vídeo, asegurate que el vídeo ha sido procesado anteriormente o prueba a acceder más tarde',
    emptyField: 'El campo no puede estar vacio',
    passwordWrong: 'La contraseña debe tener al menos 8 caracteres, entre ellos una mayúscula, una minúscula y un número',
    passwordsNotMatch: 'Las contraseñas no coinciden',
    userExistTitle: 'Usuario ya registrado',
    userExistBody: 'La cuenta de correo ya está en uso, por favor prueba con otra cuenta',
    errorSignUpUserTitle: 'Error registrar usuario',
    errorSignUpUserBody: 'Ahora mismo no hemos podido crear el usuario, por favor vuelve a intentarlo más tarde',
    invalidCodeField: 'Revisa por favor que has introducido correctamente el código de confirmación recibido en el correo',
    codeExpiredTitle: 'Código expirado',
    codeExpiredBody: 'El código ya ha expirado, por favor, solicita uno nuevo',
    confirmCodeErrorTitle: 'Error al procesar código',
    confirmCodeErrorBody: 'El código introducido o la cuenta de correo no son correctos, por favor revisa los datos',
    wrongDataLoginTitle: 'Datos incorrectos',
    wrongDataLoginBody: 'Por favor revisa que el usuario o la contraseña son correctos',
    unkownErrorLoginTitle: 'Error al iniciar sesión',
    unkownErrorLoginBody: 'Ha habido un error al iniciar sesión, por favor intentelo de nuevo más tarde',
    userNotConfirmedExceptionTitle: 'Usuario no confirmado',
    userNotConfirmedExceptionBody: 'El usuario no ha sido confirmado, por favor introduce el código enviado al correo para confirmar la cuenta antes de poder iniciar sesión',
    unkownErrorSendCodeTitle: 'Error al enviar código',
    unkownErrorSendCodeBody: 'Ahora mismo no hemos podido enviar el código de confirmación, por favor intentalo más tarde',
    errorChangeUserPasswordTitle: 'Error restablecer la contraseña',
    errorChangeUserPasswordBody: 'Ha ocurrido un error y ahora mismo no hemos podido cambiar la contraseña, por favor intentalo de nuevo más tarde',
  },
  en: {
    UknownAPIErrorTitle: 'Unknown error',
    UknownAPIErrorMessage: 'Something unexpected happens, we are going to fix it as soon as posible!',
    UknownAPIConsentErrorTitle: 'Unknown error while processing consent',
    UknownAPIProcessVideoErrorTitle: 'Unkown error while processing vídeo',
    ErrorAPIStatusTitle: 'Error getting app status',
    ErrorAPIStatusBody: 'We are experiment some issues in the aplication, its possible to some features doesnt work propertly',
    errorGetVideoApiTitle: 'Get video error',
    errorGetVideoApiBody: 'We could not get the video, make sure the video has been processed before or try to access later',
    emptyField: 'The field cannot be empty',
    passwordWrong: 'The password must be at least 8 characters long, including an uppercase, lowercase, and a number',
    passwordsNotMatch: 'Passwords do not match',
    userExistTitle: 'User already registered',
    userExistBody: 'The email account is already in use, please try with another account',
    errorSignUpUserTitle: 'Error sign up user',
    errorSignUpUserBody: 'Right now we couldn\'t create the user, please try again later',
    invalidCodeField: 'Please check that you have correctly entered the confirmation code received in the email',
    codeExpiredTitle: 'Code expired',
    codeExpiredBody: 'The code has already expired, please request a new one',
    confirmCodeErrorTitle: 'Error processing code',
    confirmCodeErrorBody: 'The code entered or the email account is not correct, please check the data',
    wrongDataLoginTitle: 'Incorrect data',
    wrongDataLoginBody: 'Please check that the username or password are correct',
    unkownErrorLoginTitle: 'failed to login',
    unkownErrorLoginBody: 'There was an error logging in, please try again later',
    userNotConfirmedExceptionTitle: 'User not confirmed',
    userNotConfirmedExceptionBody: 'The user has not been confirmed, please enter the code sent to the email to confirm the account before logging in',
    unkownErrorSendCodeTitle: 'Error sending code',
    unkownErrorSendCodeBody: 'Right now we couldn\'t send the confirmation code, please try again later',
    errorChangeUserPasswordTitle: 'Error restablecer la contraseña',
    errorChangeUserPasswordBody: 'An error has occurred and right now we have not been able to change the password, please try again later',
  },
};
