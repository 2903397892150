export default {
  es: {
    invalidUrlInputErrorMsg: 'URL introducida no válida, por favor revisa que la url pertenece a un vídeo de Youtube válido',
    serverUknownError: 'Ha ocurrido un error al procesar la solicitud',
    topicNav: 'Temas',
    mapNav: 'Mapa',
    chartNav: 'Gráficas',
    commentsNav: 'Comentarios',
    commentsByDate: 'Comentarios por fecha',
    commentsSentiment: 'Sentimiento',
    comments: 'Comentarios',
    positive: 'Positivo',
    neutral: 'Neutral',
    negative: 'Negativo',
    noResume: '-Sin resumen-',
  },
  en: {
    invalidUrlInputErrorMsg: 'Invalid URL, please check if url is a valid Youtube video url',
    serverUknownError: 'Unknown error when process the request',
    topicNav: 'Topics',
    mapNav: 'Map',
    chartNav: 'Charts',
    commentsNav: 'Comments',
    commentsByDate: 'Comments by date',
    commentsSentiment: 'Sentiment',
    comments: 'Comments',
    positive: 'Positive',
    neutral: 'Neutral',
    negative: 'Negative',
    noResume: '-No resume-',
  },
};
