export default {
  es: {
    closeOption: 'Cerrar',
    demoTitle: '¡Bienvenido a la demo de Bebop!',
    demoMessage: `Bebop está en fase de pruebas y de momento es solo un prototipo.
      Puedes probarlo de manera gratuita con un límite de 4000 comentarios por vídeo.<br/><br/>
      Si quieres ver como funciona con vídeos de más comentarios explora los ejemplos o habla con nosotros para una prueba personalizada.<br/><br/>
      ¡No te olvides de darnos tu opinión para seguir mejorando!`,
    webDiscontinuedTitle: 'Descontinuación de Bebop',
    webDiscontinuedMessage: `<p>Hemos decidido descontinuar Bebop y por lo tanto hemos deshabilitado los formularios,
      pero todavía hemos dejado unos ejemplos más abajo para que podais ojear nuestra idea.<br/><br/>
      ¡Muchas gracias a todos los que habeis contribuido a nuestra idea durante este tiempo!</p>`,
  },
  en: {
    closeOption: 'Close',
    demoTitle: 'Welcome to Bebop demo!',
    demoMessage: `Bebop is in testing phase and for now is just a prototype.
      You can probe free with a limit of 4000 comments per video.<br/><br/>
      If you want to see how if works with videos with more comments, you can explore the examples of contact with us for a custom demostration.<br/><br/>
      Don't forget to give us your feedback for keep improve the app!`,
    webDiscontinuedTitle: 'Bebop discontinuation',
    webDiscontinuedMessage: `<p>We have decided to discontinue Bebop and therefore we have disabled the forms,
      but we have still left some examples below so that you can take a look at our idea.<br/><br/>
      Thank you very much to all of you who have contributed to our idea during this time!</p>`,
  },
};
