export default {
  es: {
    emailToRecover: 'Introduce la cuenta de correo a recuperar',
    sendCode: 'Enviar código',
    code: 'Código',
    password: 'Contraseña',
    confirmPassword: 'Confirmar contraseña',
    updatePassword: 'Actualizar contraseña',
    resetPasswordSucessTitle: 'Contraseña cambiada',
    resetPasswordSucessBody: 'Contraseña cambiada correctamente',
  },
  en: {
    emailToRecover: 'Enter the email account to recover',
    sendCode: 'Send code',
    code: 'Code',
    password: 'Password',
    confirmPassword: 'Confirm password',
    updatePassword: 'Update password',
    resetPasswordSucessTitle: 'Password changed',
    resetPasswordSucessBody: 'Password changed succesfully',
  },
};
