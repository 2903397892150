<template>
  <div
    class="py-5 cover section-parallax d-flex align-items-center"
  >
    <div class="container">
      <div class="row">
        <div class="text-center mx-auto col-md-12">
          <div class="col-md-12">
            <h1>
              ¿Quieres probar Bebop?
              <span class="text-primary">¡Es gratis!</span>
            </h1>
            <h1>
              ¿Te sabe a poco la versión beta?
              <span class="text-primary">¡Contáctanos!</span>
            </h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 p-2">
          <div class="card text-center text-dark">
            <div class="card-body p-4 shadow">
              <i class="fa fa-5x fa-twitter" style="color: #55acee"></i>
              <h3>Twitter</h3>
              <ProcessTwitterForm/>
            </div>
          </div>
        </div>
        <div class="col-md-4 p-2">
          <div class="card text-center text-dark">
            <div class="card-body p-4 shadow">
              <i class="fa fa-5x fa-youtube-play" style="color: #e52d27"></i>
              <h3>Youtube</h3>
              <ProcessVideoForm />
            </div>
          </div>
        </div>
        <div class="col-md-4 p-2">
          <div class="card text-center text-dark">
            <div class="card-body p-4 shadow">
              <i class="fa fa-5x fa-twitch" style="color: #6441a4"></i>
              <h3>Twitch</h3>
              <form>
                <div class="form-group">
                  <label contenteditable="true"
                    >¿Qué stream quieres analizar?</label
                  ><input
                    type="text"
                    class="form-control"
                    id="inputmailh"
                    placeholder="url"
                  />
                  <div class="form-group">
                    <div class="form-group pt-2">
                      <label>¿Dónde mandamos los resultados?</label
                      ><input
                        type="email"
                        class="form-control"
                        id="inputpasswordh"
                        placeholder="email"
                        style="opacity: 0.5"
                      />
                      <div class="form-check text-left mt-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="form21"
                          value="on"
                        />
                        <label class="form-check-label" for="form21">
                          Acepto que se use mi correo únicamente para recibir los resultados
                        </label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <a class="btn mt-3 btn-outline-dark disabled" href="#"
                  >Analizar</a
                >
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProcessVideoForm from '@/components/ProcessVideoForm.vue';
import ProcessTwitterForm from '@/components/ProcessTwitterForm.vue';

export default {
  name: 'Forms',
  components: {
    ProcessVideoForm,
    ProcessTwitterForm,
  },
};
</script>
