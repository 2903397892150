import lodash from 'lodash';
import ApexCharts from 'apexcharts';
import constants from '@/constants/index';
import fileApi from '@/api/file';
import literalsMixin from '@/mixins/literals_mixin';

export default {
  mixins: [literalsMixin],
  data() {
    return {
      // override in component to select API media file to download like twitter or youtube
      dataFileType: '',
      diagramContainerIsVisibleRetrys: 0,
      diagramDisplayScatter: null,
      commentsScrollOffset: constants.scrollPagination.default,
      isLoading: false,
      prevClusterSelected: null,
      renderDiagramComponent: false,
      renderChartSection: true,
      sentimentBarChartElement: null,
      sentimentBarChart: constants.apexChartConfig.sentimentBarChart,
      commentsHistogramChartElement: null,
      commentsHistogramChart: constants.apexChartConfig.commentsHistogramChart,
      keywordsChartElement: null,
      keywordsChart: constants.apexChartConfig.keywordsChart,
      isKeywordsEmpty: false, // Is not keywords not draw chart
      keywordsPerCluster: null,
      clustersSearchMatch: [], // Ids of clusters contains some of selected keywords search
      searchInputKeywords: [], // Array contains the split words of input search
      formData: {
        searchInput: null,
      },
    };
  },
  async mounted() {
    // Add scroll bar to comments container
    document.getElementById('comments-container').addEventListener('scroll', this.handleScroll);

    try {
      this.isLoading = true;

      // If presigned url exist, dont generate again
      // Otherwise generate presigned url and then get video data
      if (this.$store.state.s3SignedUrl 
        && this.$store.state.s3SignedUrl.id === this.$route.params.id) {
        const videoFile = await fileApi.getProcessedFile(this.$store.state.s3SignedUrl.url, 
          this.dataFileType);
        this.$store.commit('addScatterCommentsFilterData', videoFile.data.comments_data);
        this.$store.commit('addScatterCommentsData', videoFile.data);
        this.$store.commit('addS3SignedUrl', null);
        await this.createCharts(videoFile.data.comments_data);
        this.loadKeywordsPerCluster = videoFile.data;
      } else {
        /* const videoUrl = await fileApi.getProcessedVideo(this.$route.params.id,
          this.dataFileType); */
        const videoFile = await fileApi.getLocalProcessedVideo(this.$route.params.id,
          this.dataFileType);
        this.$store.commit('addScatterCommentsData', videoFile.data);
        this.$store.commit('addScatterCommentsFilterData', videoFile.data.comments_data);
        await this.createCharts(videoFile.data.comments_data);
        this.loadKeywordsPerCluster = videoFile.data;
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.isVideoNotFound = true;
      this.$store.commit('addToastData', {
        title: this.getLiterals.errors.errorGetVideoApiTitle,
        body: this.getLiterals.errors.errorGetVideoApiBody,
        category: constants.toastStatus.error,
      });
    }
  },
  async unmounted() {
    if (this.scatterCommentsFilterData) {
      this.$store.commit('addScatterCommentsFilterData', null);
    }

    if (this.$store.state.scatterCommentsData) {
      this.$store.commit('addScatterCommentsData', null);
    }
    this.$store.commit('addScatterCommentsData', null);
  },
  watch: {
    '$store.state.scatterCommentsFilterData': function (newData) {
      this.updateSentimentChartSeries(newData);
      this.updateHistogramChartSeries(newData);
      this.updateKeywordsChartSeries(newData);
      this.commentsScrollOffset = constants.scrollPagination.default;
    },
  },
  computed: {
    loadKeywordsPerCluster: {
      get() {
        return this.keywordsPerCluster;
      },
      set(data) {
        const clusterKeywords = {};
        lodash.forEach(data.clusters, (cluster) => {
          clusterKeywords[cluster.cluster_id] = [];
        });
        lodash.forEach(data.comments_data, (comment) => {
          if (comment.keywords) {
            lodash.forEach(comment.keywords, (keyword) => {
              if (comment.cluster_id in clusterKeywords) {
                clusterKeywords[comment.cluster_id].push(keyword);
              }
            });
          }
        });
        this.keywordsPerCluster = clusterKeywords;
      },
    },
    orderedClustersByComments() {
      const self = this;
      let { clusters } = this.$store.state.scatterCommentsData;

      if (this.clustersSearchMatch && this.clustersSearchMatch.length > 0) {
        // eslint-disable-next-line arrow-body-style
        clusters = lodash.filter(clusters, (cluster) => {
          return lodash.find(
            self.clustersSearchMatch, (id) => parseInt(id, 10) === cluster.cluster_id,
          );
        });
      }
      // desc order
      return clusters.sort((a, b) => {
        if (a.cluster_count < b.cluster_count) {
          return 1;
        }
        if (a.cluster_count > b.cluster_count) {
          return -1;
        }
        return 0;
      });
    },
  },
  methods: {
    getClusterKeywordsTitle(clusterId) {
      const countKeywords = lodash.countBy(this.keywordsPerCluster[clusterId]);
      const sortKeywords = new Map(Object.entries(countKeywords).sort((a, b) => b[1] - a[1]));
      const iterator = sortKeywords.entries();
      let valueIterator = iterator.next();
      let countKeyword = 0;
      const keyValues = [];
      const valueValues = [];

      // show 20 first most uses keywords
      while (!valueIterator.done && countKeyword < 20) {
        if (valueIterator.value && valueIterator.value[0]) {
          keyValues.push(valueIterator.value[0]);
          valueValues.push(valueIterator.value[1]);
          countKeyword += 1;
        }
        valueIterator = iterator.next();
      }
      return keyValues;
    },
    removeSearchTag(value) {
      this.searchInputKeywords = this.searchInputKeywords.filter((item) => item !== value);
      this.searchKeywords();
    },
    searchKeywords() {
      if (this.formData.searchInput || this.searchInputKeywords) {
        const self = this;

        if (this.formData.searchInput) {
          this.searchInputKeywords = this.formData.searchInput.split(' ');
        }
  
        const matchCluster = [];

        lodash.forEach(this.keywordsPerCluster, (cluster, key) => {
          // Use Set to remove duplicates
          const clusterKeywords = [...new Set(cluster)];
          lodash.forEach(clusterKeywords, (keyword) => {
            let constainsKey = false;
            lodash.forEach(self.searchInputKeywords, (searchKey) => {
              if (keyword.toLowerCase() === searchKey.toLowerCase()) {
                constainsKey = true;
                return false; // break lodash for each
              }
              return true;
            });
            if (constainsKey) {
              matchCluster.push(key);
              return false; // break lodash for each
            }
            return true;
          });
        });
        this.clustersSearchMatch = matchCluster;
        this.formData.searchInput = null;
      }
    },
    async createCharts(data) {
      this.sentimentBarChartElement = await new ApexCharts(
        document.querySelector('#sentiment-chart'), this.sentimentBarChart,
      ).render();
      this.commentsHistogramChartElement = await new ApexCharts(
        document.querySelector('#histogram-chart'), this.commentsHistogramChart,
      ).render();
      this.keywordsChartElement = await new ApexCharts(
        document.querySelector('#keywords-chart'), this.keywordsChart,
      ).render();

      this.$nextTick(() => {
        this.updateSentimentChartSeries(data);
        this.updateHistogramChartSeries(data);
        this.updateKeywordsChartSeries(data);
      });
      // this.updateSentimentChartSeries(data);
      // this.updateHistogramChartSeries(data);
      // this.updateKeywordsChartSeries(data);
    },
    updateSentimentChartSeries(comments) {
      this.sentimentBarChart.series = [{
        name: this.getLiterals.dashboard.positive,
        data: [
          this.getPositiveComments(comments).length,
        ],
      },
      {
        name: this.getLiterals.dashboard.neutral,
        data: [
          this.getNeutralComments(comments).length,
        ],
      },
      {
        name: this.getLiterals.dashboard.negative,
        data: [
          this.getNegativeComments(comments).length,
        ],
      }];
      this.sentimentBarChart.title = {
        text: this.getLiterals.dashboard.commentsSentiment,
        align: 'left',
        margin: 20,
        offsetX: 10,
      };
      this.sentimentBarChart.xaxis = {
        
        categories: [this.getLiterals.dashboard.comments],
      };
      if (this.sentimentBarChartElement) {
        this.sentimentBarChartElement.updateOptions(this.sentimentBarChart);
      }
    },
    updateHistogramChartSeries(comments) {
      const formattedData = [];
      comments.forEach((element) => {
        const commentsDate = new Date(element.created_at);
        // const seconds = commentsDate.getSeconds();
        const minutes = commentsDate.getMinutes();
        const hours = commentsDate.getHours();
        const formattedcommentsDate = commentsDate
          .setHours(hours, minutes, 0, 0);
        const dateIndex = formattedData.findIndex((date) => date[0] === formattedcommentsDate);
        if (dateIndex !== -1) {
          formattedData[dateIndex][1] += 1;
        } else {
          formattedData.push([formattedcommentsDate, 1]);
        }
      });
      // asc order
      const sortFormattedData = formattedData.sort((a, b) => {
        if (a[0] < b[0]) {
          return -1;
        }
        if (a[0] > b[0]) {
          return 1;
        }
        return 0;
      });

      this.commentsHistogramChart.series = [{
        name: this.getLiterals.dashboard.comments,
        data: sortFormattedData,
      }];
      this.commentsHistogramChart.title = {
        text: this.getLiterals.dashboard.commentsByDate,
        align: 'left',
        margin: 20,
        offsetX: 10,
      };
      if (this.commentsHistogramChartElement) {
        this.commentsHistogramChartElement.updateOptions(this.commentsHistogramChart);
      }
    },
    updateKeywordsChartSeries(comments) {
      const keywords = [];
      lodash.forEach(comments, (comment) => {
        lodash.forEach(comment.keywords, (keyword) => {
          keywords.push(keyword);
        });
      });
      if (keywords.length === 0) {
        this.isKeywordsEmpty = true;
        if (this.keywordsChartElement) {
          this.keywordsChartElement.destroy();
          this.keywordsChartElement = null;
        }
      } else {
        const countKeywords = lodash.countBy(keywords);

        const sortKeywords = new Map(Object.entries(countKeywords).sort((a, b) => b[1] - a[1]));
        const iterator = sortKeywords.entries();
        let valueIterator = iterator.next();
        let countKeyword = 0;
        const keyValues = [];
        const valueValues = [];

        while (!valueIterator.done && countKeyword < 10) {
          if (valueIterator.value && valueIterator.value[0]) {
            keyValues.push(valueIterator.value[0]);
            valueValues.push(valueIterator.value[1]);
            countKeyword += 1;
          }
          valueIterator = iterator.next();
        }

        this.keywordsChart.series = [{
          name: 'keywords',
          data: valueValues,
        }];
        this.keywordsChart.xaxis = {
          categories: keyValues,
        };
        if (this.keywordsChartElement) {
          this.keywordsChartElement.updateOptions(this.keywordsChart);
        }
        this.keywordsChart.title = {
          text: 'keywords',
          margin: 10,
          offsetX: 10,
        };
      }
    },
    loadAllCluster() {
      if (this.prevClusterSelected) {
        this.prevClusterSelected.classList.remove('active');
      }
      this.$store.commit('addScatterCommentsFilterData', this.scatterCommentData());
    },
    loadClusterComments(cluster, event) {
      if (event.target !== this.prevClusterSelected) {
        event.target.classList.add('active');
        if (this.prevClusterSelected) {
          this.prevClusterSelected.classList.remove('active');
        }
        this.prevClusterSelected = event.target;
        const clusterId = cluster.cluster_id;
        const comments = this.$store.state.scatterCommentsData.comments_data.filter(
          (comment) => comment.cluster_id === clusterId,
        );

        this.$store.commit('addScatterCommentsFilterData', comments);
      }
    },
    getClusterTitle(clusterTitle, clusterId) {
      if (clusterId === -1) {
        return 'Random';
      }

      if (clusterTitle) {
        // const titlesParagraph = clusterTitle.split('.');
        // let resumeTitle = '';

        // for (let i = 0; i < titlesParagraph.length; i += 1) {
        //   if (i < 5) {
        //     resumeTitle += `${titlesParagraph}. `;
        //   } else {
        //     break;
        //   }
        // }
        return clusterTitle;
      }

      return this.getLiterals.dashboard.noResume;
    },
    getPositiveComments(comments) {
      return comments.filter((comment) => comment.sentiment === 'positive');
    },
    getNeutralComments(comments) {
      return comments.filter((comment) => comment.sentiment === 'neutral');
    },
    getNegativeComments(comments) {
      return comments.filter((comment) => comment.sentiment === 'negative');
    },
    showMap() {
      const self = this;
      this.renderDiagramComponent = false;
      const waitToContainerVisible = setInterval(() => {
        if (self.diagramContainerIsVisibleRetrys >= 5) {
          self.diagramContainerIsVisibleRetrys = 0;
          clearInterval(waitToContainerVisible);
        }

        const element = document.getElementById('map');

        if (window.getComputedStyle(element, null).display) {
          self.$store.commit('addScatterCommentsFilterData', self.scatterCommentData());
          self.diagramDisplayScatter = this.$store.state.scatterCommentsData;
          self.renderDiagramComponent = true;
          clearInterval(waitToContainerVisible);
          self.diagramContainerIsVisibleRetrys = 0;
        }

        self.diagramContainerIsVisibleRetrys += 1;
      }, 1000);
    },
    showCharts() {
      this.sentimentBarChartElement.destroy();
      this.commentsHistogramChartElement.destroy();

      if (this.keywordsChartElement && !this.isKeywordsEmpty) {
        this.keywordsChartElement.destroy();
      }
      this.$nextTick(async () => {
        this.sentimentBarChartElement = await new ApexCharts(
          document.querySelector('#sentiment-chart'), this.sentimentBarChart,
        ).render();

        this.commentsHistogramChartElement = await new ApexCharts(
          document.querySelector('#histogram-chart'), this.commentsHistogramChart,
        ).render();

        if (!this.isKeywordsEmpty) {
          this.keywordsChartElement = await new ApexCharts(
            document.querySelector('#keywords-chart'), this.keywordsChart,
          ).render();
        }

        const self = this;
        // logic to redraw...
        self.updateSentimentChartSeries(self.$store.state.scatterCommentsFilterData);
        self.updateHistogramChartSeries(self.$store.state.scatterCommentsFilterData);
        self.updateKeywordsChartSeries(self.$store.state.scatterCommentsFilterData);

        const waitToContainerVisible = setInterval(() => {
          if (self.diagramContainerIsVisibleRetrys >= 5) {
            self.diagramContainerIsVisibleRetrys = 0;
            clearInterval(waitToContainerVisible);
          }
  
          const element = document.getElementById('charts');
  
          if (window.getComputedStyle(element, null).display) {
            self.updateSentimentChartSeries(self.$store.state.scatterCommentsFilterData);
            self.updateHistogramChartSeries(self.$store.state.scatterCommentsFilterData);
            self.updateKeywordsChartSeries(self.$store.state.scatterCommentsFilterData);
            clearInterval(waitToContainerVisible);
            self.diagramContainerIsVisibleRetrys = 0;
          }
  
          self.diagramContainerIsVisibleRetrys += 1;
        }, 500);
        // this.sentimentBarChartElement.updateOptions(this.sentimentBarChart);
        // this.commentsHistogramChartElement.updateOptions(this.commentsHistogramChart);
        // this.keywordsChartElement.updateOptions(this.keywordsChart);
        // this.updateSentimentChartSeries(this.$store.state.scatterCommentsFilterData);
        this.sentimentBarChartElement = await new ApexCharts(
          document.querySelector('#sentiment-chart'), this.sentimentBarChart,
        ).render();
        this.commentsHistogramChartElement = await new ApexCharts(
          document.querySelector('#histogram-chart'), this.commentsHistogramChart,
        ).render();
        if (!this.isKeywordsEmpty) {
          this.keywordsChartElement = await new ApexCharts(
            document.querySelector('#keywords-chart'), this.keywordsChart,
          ).render();
        }
      });
    },
    getCommentsToDisplay() {
      let currentOffset = this.commentsScrollOffset;
      if (this.$store.state.scatterCommentsFilterData) {
        if (currentOffset > this.$store.state.scatterCommentsFilterData.length) {
          currentOffset = this.$store.state.scatterCommentsFilterData.length;
        }

        return this.$store.state.scatterCommentsFilterData.slice(0, currentOffset);
      }
      return null;
    },
    scatterCommentData() {
      if (this.$store.state.scatterCommentsData) {
        return this.$store.state.scatterCommentsData.comments_data;
      }
      return null;
    },
    handleScroll() {
      const element = document.getElementById('comments-container');
      if (element.scrollHeight - element.scrollTop <= element.clientHeight + 1) {
        this.loadMoreComments();
      }
    },
    loadMoreComments() {
      this.commentsScrollOffset += constants.scrollPagination.default;
    },
  },
};
