import exampleVideos from '@/constants/example_videos';
import apexChartConfig from '@/constants/apexcharts_default_config';

export default {
  TWEET_MAX_SEARCH_LIMIT: 512,
  TWEET_MIN_SEARCH_LIMIT: 1,
  timers: {
    toast: {
      default: 5000,
      large: 10000,
      short: 2000,
    },
    tooltip: 3000,
    youtubePostInterval: 5000,
    youtubePostTimeout: 120000,
  },
  toastStatus: {
    successed: 'SUCCESED',
    warning: 'WARNING',
    error: 'ERROR',
    info: 'INFO',
  },
  scrollPagination: {
    default: 20,
  },
  scatterType: {
    twitter: 'twitter',
    youtube: 'youtube',
  },
  exampleVideos: exampleVideos.videos,
  apexChartConfig: apexChartConfig,
};
