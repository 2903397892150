export default {
  es: {
    comments: 'comentarios',
    seeMap: 'Explorar',
  },
  en: {
    comments: 'comments',
    seeMap: 'Explore',
  },
};
