import literals from '@/literals/index';

export default {
  computed: {
    getLiterals() {
      const literalsSelectedLanguage = {};
      Object.keys(literals).forEach((literalKey) => {
        literalsSelectedLanguage[literalKey] = literals[literalKey][this.$store.state.lang];
      });
      return literalsSelectedLanguage;
    },
  },
};
