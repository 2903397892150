<template>
  <div class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-md-10">
          <h1 class="mb-3">{{ getLiterals.how.instructionTitle }}</h1>
          <h4>{{ getLiterals.how.howAnalyze }}</h4>
          <p>{{ getLiterals.how.analyzeFirstStep }}</p>
          <img class="img-fluid d-block mx-auto pb-3" 
          src="../assets/ca56c75446d32825584cc3dae0c80824.gif" 
          style='width: 40%;'>
          <p>{{ getLiterals.how.analyzeSecondStep }}</p>
          <img class="img-fluid d-block mx-auto pb-4" 
          src="../assets/Captura.jpg"  style='width: 80%;'>
          <h4>{{ getLiterals.how.howOrganizeComments }}</h4>
          <p>{{ getLiterals.how.organizeStep }}</p>
          <img class="img-fluid d-block mx-auto pb-4" 
          src="../assets/2f705a4dab61276199d8e97bfd74cde7.gif"  style='width: 80%;'>
          <h4>{{ getLiterals.how.whatSizeMeans }}</h4>
          <p class="pb-3">{{ getLiterals.how.whatSizeDescription }}<br></p>
          <h4>{{ getLiterals.how.whatColorsMeans }}</h4>
          <p>{{ getLiterals.how.whatColorAllSelected }}</p>
          <ul>
            <li>{{ getLiterals.how.whatColorsMeansNegative }}</li>
            <li>{{ getLiterals.how.whatColorsMeansPositive }}</li>
            <li class="pb-3">{{ getLiterals.how.whatColorsMeansNeutral }}</li>
          </ul>
          <p>{{ getLiterals.how.whatColorGroupSelected }}</p>
          <h4>{{ getLiterals.how.howInteractWithTheMap }}</h4>
          <p>{{ getLiterals.how.howInteractFirstParagraph }}</p>
          <p>{{ getLiterals.how.howInteractSecondParagraph }}</p>
          <p>{{ getLiterals.how.howInteractThirdParagraph }}</p>
          <img class="img-fluid d-block mx-auto" 
          src="../assets/1d99058cd86ef013b4e9a328b827a0d3.gif"  style='width: 80%;'>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import literalsMixin from '@/mixins/literals_mixin';

export default {
  mixins: [literalsMixin],
};
</script>
