<template>
  <div
    data-test-id="pitch-elevator-section"
    class="py-md-3 py-lg-5 min-vh-100 align-items-center section-parallax">
    <!-- <ProcessVideoForm /> -->
    <div class="container py-5">
      <div class="row">
        <div class="px-md-5 col-md-7">
          <h1 class="mb-4 display-3">{{ getLiterals.home.commentsTitle }}</h1>
          <p class="lead mb-4">
            {{ getLiterals.home.bebopDescription }}
          </p>
          <router-link
            to="/forms"
            v-slot="{ href, navigate}" custom>
            <a class="btn btn-lg btn-primary"
              :href="href" @click="navigate">{{ getLiterals.home.tryFree }}
            </a>
          </router-link>
          <router-link
            to="/contact"
            v-slot="{ href, navigate}" custom>
            <a class="btn btn-outline-primary btn-lg ml-2"
              :href="href" @click="navigate">{{ getLiterals.home.contactUs }}
            </a>
          </router-link>
        </div>
        <div class="px-md-6 col-md-5">
          <img class="img-fluid d-block mx-auto" src="../assets/New_notifications-amico.png">
        </div>
      </div>
    </div>
  </div>
  <div
    data-test-id="examples-section"
    class="py-1 py-md-3 py-lg-5">
    <div class="container">
      <div class="row">
        <div class="text-center mx-auto col-md-12">
          <h2 class="h1">{{ getLiterals.home.example }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <ul class="nav nav-pills d-flex justify-content-center py-3">
            <li class="nav-item" :key="index"
              v-for="(tab, index) in getPreviewVideos">
              <a
                v-if="!tab.isDisabled"
                :data-test-id="`example-${tab.category}`"
                :href="`#${tab.category}`"
                class="nav-link"
                data-toggle="pill"
                :data-target="`#${tab.category}`"
                :id="`#${tab.category}-tab`"
                role="tab"
                :aria-controls="tab.category"
                :aria-selected="index === 0 ? 'true' : 'false'"
                :class="index === 0 ? 'active' : ''"
              >
                {{ tab.category }}
              </a>
              <p class="nav-link disabled" v-else>
                {{ tab.category }}
              </p>
            </li>
          </ul>
          <div class="tab-content mt-2">
            <div
              :key="index"
              v-for="(tab, index) in getPreviewVideos"
              class="tab-pane fade"
              :class="index === 0 ? 'active show' : ''"
              :id="tab.category" role="tabpanel"
              :aria-labelledby="`#${tab.category}-tab`">
              <div class="row" v-if="!tab.isDisabled">
                <YoutubeVideoExample
                  :key="video.videoID"
                  v-for="video in tab.videos"
                  :previewExample="video">
                </YoutubeVideoExample>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="py-4">
    <div class="container">
      <div class="row">
        <div class="px-lg-5 d-flex flex-column justify-content-center col-lg-6">
          <h2 class="h1">{{ getLiterals.home.language }}</h2>
          <p class="mb-3 lead">{{ getLiterals.home.languageDescription }}</p>
          <p class="text-muted">{{ getLiterals.home.supportedLanguages }}</p>
        </div>
        <div class="col-lg-6"> <img class="img-fluid d-block" src="../assets/online-world-amico.png" alt="world-languages"> </div>
      </div>
    </div>
  </div>
  <hr>
  <div class="py-4">
    <div class="container">
      <div class="row">
        <div class="col-md-6"> <img class="img-fluid d-block" src="../assets/social-media-amico.png" alt="social-platforms"> </div>
        <div class="px-md-5 p-3 col-md-6 d-flex flex-column justify-content-center">
          <h2 class="h1">{{ getLiterals.home.platformDisponibility }}</h2>
          <p class="mb-3 lead">{{ getLiterals.home.platformDescription }}</p>
          <div class="row">
            <div class="col-md-12">
                <i
                  class="fa fa-3x mr-1 mr-md-3 mr-lg-4 d-inline fa-youtube-play"
                  style="color: #e52d27"
                  aria-hidden="true"
                  title="youtube"></i>
                <i
                  class="fa fa-3x mr-1 mr-md-3 mr-lg-4 d-inline fa-twitter"
                  style="color: #55acee"
                  aria-hidden="true"
                  title="twitter"></i>
                <i class="fa fa-3x mr-1 mr-md-3 mr-lg-4 text-muted d-inline fa-instagram" aria-hidden="true" title="instagram"></i>
                <i class="fa fa-3x mr-1 mr-md-3 mr-lg-4 text-muted d-inline fa-twitch" aria-hidden="true" title="twitch"></i>
                <i class="fa fa-3x mr-1 mr-md-3 mr-lg-4 text-muted d-inline fa-amazon" aria-hidden="true" title="amazon"></i>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <div class="py-5 text-center text-light bg-secondary" style="" >
    <div class="container">
      <div class="row">
        <div class="mx-auto p-4 col-md-7">
          <h1 class="mb-4">{{ getLiterals.home.enterpriseCall }}</h1> <router-link
            to="/contact"
            v-slot="{ href, navigate}" custom>
            <a class="btn btn-primary text-white btn-lg"
              :href="href" @click="navigate">{{ getLiterals.home.contactUs }}
            </a>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <div class="py-5 text-center" >
    <div class="container">
      <div class="row">
        <div class="mx-auto col-md-12">
          <h1 class="mb-3">{{ getLiterals.home.team }}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-6 p-4">
          <img class="img-fluid d-block mb-3 mx-auto rounded-circle" 
            src="../assets/julian_ballesteros_linkedin.jpg"
            width="100" alt="Julián Ballesteros">
          <h4> <b>Julián Ballesteros</b></h4>
          <p>Founder&nbsp; · Deep Learner · Data Scientist</p>
          <a class="btn btn-link" href="https://www.linkedin.com/in/julian-bal-gar/" style="color:#0976b4" target="_blank">
            <i class="fa fa-linkedin-square fa-fw fa-1x py-1"></i> Linkedin
          </a>
        </div>
        <div class="col-lg-6 col-6 p-4">
          <img class="img-fluid d-block mb-3 mx-auto rounded-circle" 
            src="../assets/miguel_cherubini_linkedin.jpg"
            width="100" alt="Miguel Cherubini">
          <h4> <b>Miguel Cherubini</b></h4>
          <p>Founder&nbsp; · QA Engineer · Cloud Architect</p>
          <a class="btn btn-link" href="https://www.linkedin.com/in/m-cherubini-hervas/" style="color:#0976b4" target="_blank">
            <i class="fa fa-linkedin-square fa-fw fa-1x py-1"></i>Linkedin
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="py-5 text-center section-parallax">
    <div class="container">
      <div class="row">
        <div class="mx-auto col-10 bg-white p-2 p-md-3 p-lg-5 col-md-5 shadow">
          <h2 class="h1">{{ getLiterals.home.feedback }}</h2>
          <img class="img-fluid d-block pb-3" src="../assets/deconstructed-robot-amico.png" alt="building-robot">
          <router-link
            to="/contact"
            v-slot="{ href, navigate}" custom>
            <a class="btn btn-primary text-white btn-lg"
              :href="href" @click="navigate">{{ getLiterals.home.contactUs }}
            </a>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint max-len: ["error", { "code": 205 }] */
import YoutubeVideoExample from '@/components/YoutubeVideoExample.vue';
import constants from '@/constants/index';
import literalsMixin from '@/mixins/literals_mixin';

export default {
  name: 'Home',
  components: {
    YoutubeVideoExample,
  },
  mixins: [literalsMixin],
  data() {
    return {
      latestVideosData: null,
      previewResults: 6,
      videosPerRows: 3,
    };
  },
  computed: {
    // push latest video results in array with two dimensions to create display logic
    getLatestVideosPerRows() {
      const rows = [];

      if (this.latestVideosData.latestVideos.length >= this.videosPerRows) {
        for (let i = 0; i < this.latestVideosData.latestVideos.length; i += this.videosPerRows) {
          // if cannot draw row with all elements, dont add the row to display data
          if (!(i + this.videosPerRows > this.latestVideosData.latestVideos.length)) {
            rows.push(
              this.latestVideosData.latestVideos.slice(i, i + this.videosPerRows),
            );
          }
        }
      }
      return rows;
    },
    getPreviewVideos() {
      const tabs = [];

      constants.exampleVideos.forEach((element) => {
        const category = tabs.find((tab) => tab.category === element.category[this.$store.state.lang]);

        if (!category) {
          const newCategory = {
            category: element.category[this.$store.state.lang],
            videos: [element],
            isDisabled: element.isDisabled,
          };
          tabs.push(newCategory);
        } else {
          category.videos.push(element);
        }
      });
      return tabs;
    },
  },
};
</script>
