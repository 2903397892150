/* eslint-disable no-param-reassign */

import Joi from 'joi';
import passwordComplexity from 'joi-password-complexity';
import store from '../store/index';
import literals from '../literals/index';

const complexityOptions = {
  min: 8,
  max: 30,
  lowerCase: 1,
  upperCase: 1,
  numeric: 1,
  requirementCount: 3,
};

const signUpFormSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'string.empty':
            err.message = literals.forms[store.state.lang].invalidEmailInputErrorMsg;
            break;
          case 'string.base':
            err.message = literals.forms[store.state.lang].invalidEmailInputErrorMsg;
            break;
          case 'string.email':
            err.message = literals.forms[store.state.lang].invalidEmailInputErrorMsg;
            break;
          case 'any.required':
            err.message = literals.forms[store.state.lang].invalidEmailInputErrorMsg;
            break;
          default:
            err.message = literals.forms[store.state.lang].invalidEmailInputErrorMsg;
            break;
        }
      });
      return errors;
    }),
  password: passwordComplexity(complexityOptions)
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'passwordComplexity.lowercase':
            err.message = literals.errors[store.state.lang].passwordWrong;
            break;
          case 'passwordComplexity.uppercase':
            err.message = literals.errors[store.state.lang].passwordWrong;
            break;
          case 'passwordComplexity.numeric':
            err.message = literals.errors[store.state.lang].passwordWrong;
            break;
          case 'passwordComplexity.tooShort':
            err.message = literals.errors[store.state.lang].passwordWrong;
            break;
          default:
            err.message = literals.errors[store.state.lang].passwordWrong;
            break;
        }
      });
      return errors;
    }),
  confirmPassword: Joi.ref('password'),
  consentCheck: Joi.boolean().required(),
});
export default signUpFormSchema;
