<template>
  <div id="sign-up-container text-dark">
    <div class="m-4 shadow p-3">
      <form
        id="form-sign-up"
        data-test-id="form-sign-up"
        novalidate
        v-show="!enableFormVerificationCode"
      >
        <div class="form-group">
          <label>Email</label>
          <p
            class="form-errors text-left"
            v-if="formErrors.email"
            data-test-id="input-error-text-signup-email">
            {{ formErrors.email }}
          </p>
          <input
            type="email"
            class="form-control"
            name="signup-email"
            autocomplete="on"
            :class="emailInputValidation"
            placeholder="email"
            v-model="formData.email"
            id="signup-email"
            aria-describedby="Sign up user email"
            data-test-id="signup-email"
            required
          />
          <div class="form-group">
            <div class="form-group pt-2">
              <label>{{ getLiterals.signUpComponent.password }}</label>
              <p
                class="form-errors text-left"
                v-if="formErrors.password"
                data-test-id="input-error-signup-password">
                {{ formErrors.password }}
              </p>
              <input
                type="password"
                class="form-control mb-2"
                name="input-signup-password"
                autocomplete="on"
                :class="passwordInputValidation"
                :placeholder="getLiterals.signUpComponent.password"
                v-model="formData.password"
                id="input-signup-password"
                aria-describedby="Sign up user password"
                data-test-id="input-signup-password"
                required
              />

              <label>{{ getLiterals.signUpComponent.confirmPassword }}</label>
              <p
                class="form-errors text-left"
                v-if="formErrors.confirmPassword"
                data-test-id="input-error-signup-confirm-password">
                {{ formErrors.confirmPassword }}
              </p>
              <input
                type="password"
                class="form-control"
                name="input-signup-password"
                autocomplete="on"
                :class="confirmPasswordInputvalidation"
                :placeholder="getLiterals.signUpComponent.confirmPassword"
                v-model="formData.confirmPassword"
                id="input-signup-confirm-password"
                aria-describedby="Sign up user password"
                data-test-id="input-signup-confirm-password"
                required/>

              <div class="form-check text-left mt-3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="video-check-consent"
                  value="on"
                  data-test-id="input-video-check-consent"
                  v-model="formData.consentCheck"
                />
                <label class="form-check-label" for="video-check-consent">
                  {{ getLiterals.signUpComponent.marketingAgree }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="text-md-center">
          <button
            type="submit" class="btn btn-outline-primary mt-3" href="#"
            data-toggle="modal"
            @click.prevent="signUpUser"
            :disabled="isLoading"
            id="button-sign-up"
            data-test-id="button-sign-up"
          >
          {{ getLiterals.signUpComponent.signUp }}
        </button>
        </div>
      </form>

      <form
        id="form-sign-up-verification-code"
        data-test-id="form-sign-up-verification-code"
        novalidate
        v-show="enableFormVerificationCode"
      >
        <div class="form-group">
          <label>Email</label>
          <p
            class="form-errors text-left"
            v-if="formConfirmErrors.email"
            data-test-id="input-error-text-signup-email">
            {{ formConfirmErrors.email }}
          </p>
          <input
            type="email"
            class="form-control mb-2"
            name="signup-confirm-email"
            autocomplete="on"
            placeholder="Email"
            v-model="formData.email"
            id="signup-confirm-email"
            aria-describedby="Sign up email verification"
            data-test-id="signup-confirm-email"
            required
          />
          <label>{{ getLiterals.signUpComponent.code }}</label>
          <p
            class="form-errors text-left"
            v-if="formConfirmErrors.code"
            data-test-id="input-error-text-signup-email">
            {{ formConfirmErrors.code }}
          </p>
          <input
            type="text"
            class="form-control"
            name="signup-code"
            autocomplete="off"
            :placeholder="getLiterals.signUpComponent.code"
            v-model="formConfirmCode.code"
            id="signup-code"
            aria-describedby="Sign up email code verification"
            data-test-id="signup-code"
            required
          />
        </div>
        <div class="text-md-center">
          <button
            type="submit"
            class="btn btn-outline-primary mt-3 mr-2"
            href="#"
            data-toggle="modal"
            @click.prevent="resendCode"
            :disabled="isLoading"
            id="button-sign-up-resend"
            data-test-id="button-sign-up-resend"
          >
            {{ getLiterals.signUpComponent.resendCode }}
          </button>

          <button
            type="submit" class="btn btn-outline-primary mt-3" href="#"
            data-toggle="modal"
            @click.prevent="signUpConfirmCode"
            :disabled="isLoading"
            id="button-send-confirm-code"
            data-test-id="button-send-confirm-code"
          >
            {{ getLiterals.signUpComponent.confirm }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import literalsMixin from '@/mixins/literals_mixin';
import signUpFormSchema from '../schemas/signup_form_joi';
import signUpFormVerificationSchema from '../schemas/signup_form_verification_joi';
import signUpFormVerificationResendSchema from '../schemas/signup_form_verification_resend_joi';
import literals from '../literals/index';
import constants from '../constants/index';

export default {
  name: 'SignUpForm',
  mixins: [literalsMixin],
  data() {
    return {
      formHasErrors: false,
      formErrors: {
        email: '',
        password: '',
        confirmPassword: '',
      },
      formConfirmHasErrors: false,
      formConfirmErrors: {
        email: '',
        code: '',
      },
      formData: {
        email: '',
        password: '',
        confirmPassword: '',
        consentCheck: false,
      },
      formConfirmCode: {
        code: '',
      },
      isLoading: false,
    };
  },
  computed: {
    emailInputValidation() {
      if (this.formHasErrors) {
        return this.formErrors.email ? 'is-invalid' : 'is-valid';
      }
      return '';
    },
    passwordInputValidation() {
      if (this.formHasErrors) {
        return this.formErrors.password ? 'is-invalid' : 'is-valid';
      }
      return '';
    },
    confirmPasswordInputvalidation() {
      if (this.formHasErrors) {
        return (this.formErrors.confirmPassword 
          || this.formErrors.password) ? 'is-invalid' : 'is-valid';
      }
      return '';
    },
    enableFormVerificationCode: {
      get() {
        return this.$store.state.enableFormVerificationCode;
      },
      set(enabled) {
        this.$store.commit('setEnableFormVerificationCode', enabled);
      },
    },
  },
  mounted() {
    if (this.$store.state.loginEmail) {
      this.formData.email = this.$store.state.loginEmail;
      this.$store.commit('setLoginEmail', null);
    }
  },
  methods: {
    async signUpUser() {
      this.formErrors.email = '';
      this.formErrors.password = '';
      this.formErrors.confirmPassword = '';
      this.formHasErrors = false;

      const validation = signUpFormSchema.validate(this.formData, {
        abortEarly: false,
      });
      if (!validation.error) {
        try {
          this.isLoading = true;
          await Auth.signUp({
            username: this.formData.email,
            password: this.formData.password,
            attributes: {
              'custom:lang': navigator.language,
              'custom:marketing_agree': this.formData.consentCheck ? 'yes' : 'no',
            },
          });
          this.isLoading = false;
          this.enableFormVerificationCode = true;
        } catch (error) {
          this.isLoading = false;
          if (error.code) {
            switch (error.code) {
              case 'UsernameExistsException':
                this.$store.commit('addToastData', {
                  title: literals.errors[this.$store.state.lang].userExistTitle,
                  body: literals.errors[this.$store.state.lang].userExistBody,
                  category: constants.toastStatus.error,
                });
                break;
              default:
                this.$store.commit('addToastData', {
                  title: literals.errors[this.$store.state.lang].errorSignUpUserTitle,
                  body: literals.errors[this.$store.state.lang].errorSignUpUserBody,
                  category: constants.toastStatus.error,
                });
                break;
            }
          } else {
            this.$store.commit('addToastData', {
              title: literals.errors[this.$store.state.lang].errorSignUpUserTitle,
              body: literals.errors[this.$store.state.lang].errorSignUpUserBody,
              category: constants.toastStatus.error,
            });
          }
        }
      } else {
        this.formHasErrors = true;
        this.checkFormErrors(validation.error.details);
      }
    },
    async signUpConfirmCode() {
      this.formConfirmErrors.email = '';
      this.formConfirmErrors.code = '';
      this.formConfirmHasErrors = false;

      const validation = signUpFormVerificationSchema.validate({
        email: this.formData.email,
        code: this.formConfirmCode.code,
      }, {
        abortEarly: false,
      });
      if (!validation.error) {
        try {
          this.isLoading = true;
          await Auth.confirmSignUp(this.formData.email, this.formConfirmCode.code);
          this.$store.commit('setLoginEmail', this.formData.email);
          this.$store.commit('addToastData', {
            title: literals.signUpComponent[this.$store.state.lang].signUpSucessTitle,
            body: literals.signUpComponent[this.$store.state.lang].signUpSucessBody,
            category: constants.toastStatus.successed,
          });
          this.$router.push('/login');
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          if (error.code) {
            switch (error.code) {
              case 'CodeMismatchException':
                this.$store.commit('addToastData', {
                  title: literals.errors[this.$store.state.lang].confirmCodeErrorTitle,
                  body: literals.errors[this.$store.state.lang].confirmCodeErrorBody,
                  category: constants.toastStatus.error,
                });
                break;
              case 'ExpiredCodeException':
                this.$store.commit('addToastData', {
                  title: literals.errors[this.$store.state.lang].codeExpiredTitle,
                  body: literals.errors[this.$store.state.lang].codeExpiredBody,
                  category: constants.toastStatus.error,
                });
                break;
              default:
                this.$store.commit('addToastData', {
                  title: literals.errors[this.$store.state.lang].errorSignUpUserTitle,
                  body: literals.errors[this.$store.state.lang].errorSignUpUserBody,
                  category: constants.toastStatus.error,
                });
                break;
            }
          } else {
            this.$store.commit('addToastData', {
              title: literals.errors[this.$store.state.lang].errorSignUpUserTitle,
              body: literals.errors[this.$store.state.lang].errorSignUpUserBody,
              category: constants.toastStatus.error,
            });
          }
        }
      } else {
        this.formConfirmHasErrors = true;
        this.checkConfirmFormErrors(validation.error.details);
      }
    },
    async resendCode() {
      this.formConfirmErrors.email = '';
      this.formConfirmHasErrors = false;

      const validation = signUpFormVerificationResendSchema.validate({
        email: this.formData.email,
      }, {
        abortEarly: false,
      });
      if (!validation.error) {
        try {
          this.isLoading = true;
          await Auth.resendSignUp(this.formData.email);
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          if (error.code) {
            switch (error.code) {
              case 'UsernameExistsException':
                this.$store.commit('addToastData', {
                  title: literals.errors[this.$store.state.lang].userExistTitle,
                  body: literals.errors[this.$store.state.lang].userExistBody,
                  category: constants.toastStatus.error,
                });
                break;
              default:
                this.$store.commit('addToastData', {
                  title: literals.errors[this.$store.state.lang].errorSignUpUserTitle,
                  body: literals.errors[this.$store.state.lang].errorSignUpUserBody,
                  category: constants.toastStatus.error,
                });
                break;
            }
          } else {
            this.$store.commit('addToastData', {
              title: literals.errors[this.$store.state.lang].errorSignUpUserTitle,
              body: literals.errors[this.$store.state.lang].errorSignUpUserBody,
              category: constants.toastStatus.error,
            });
          }
        }
      } else {
        this.formConfirmHasErrors = true;
        this.checkConfirmResendFormErrors(validation.error.details);
      }
    },
    checkFormErrors(errors) {
      const self = this;
      errors.forEach((error) => {
        switch (error.context.key) {
          case 'password':
            self.formErrors.password = error.message;
            break;
          case 'confirmPassword':
            self.formErrors.confirmPassword = literals
              .errors[this.$store.state.lang].passwordsNotMatch;
            break;
          case 'email':
            self.formErrors.email = error.message;
            break;
          default:
            break;
        }
      });
    },
    checkConfirmFormErrors(errors) {
      const self = this;
      errors.forEach((error) => {
        switch (error.context.key) {
          case 'code':
            self.formConfirmErrors.code = error.message;
            break;
          case 'email':
            self.formConfirmErrors.email = error.message;
            break;
          default:
            break;
        }
      });
    },
    checkConfirmResendFormErrors(errors) {
      const self = this;
      errors.forEach((error) => {
        switch (error.context.key) {
          case 'email':
            self.formConfirmErrors.email = error.message;
            break;
          default:
            break;
        }
      });
    },
  },
};
</script>
