export default {
  es: {
    commentsTitle: 'Miles de comentarios de un vistazo',
    bebopDescription: '¡Bebop cambia la manera de extraer conocimiento de miles de comentarios de una manera facil y rápida!',
    tryFree: '¡Pruébalo gratis!',
    example: 'Ejemplos',
    language: 'Idiomas',
    languageDescription: `¡Bebop ha pasado mucho tiempo viajando por el
      mundo para poder analizar comentarios vengan de donde vengan!`,
    supportedLanguages: `Árabe, chino, inglés, francés, alemán, italiano, japonés, coreano, holandés, polaco,
      español, tailandés, turco y ruso`,
    platformDisponibility: 'Plataformas disponibles',
    platformDescription: '¡Actualmente Bebop analiza comentarios de youtube y twitter pero queremos ampliar nuestras fronteras!',
    enterpriseCall: '¿Quieres usar Bebop para los procesos de tu empresa?',
    team: 'Equipo',
    feedback: '¡Necesitamos tu feedback!',
    contactUs: 'Contáctanos',
  },
  en: {
    commentsTitle: 'Thousands of comments at a glance',
    bebopDescription: 'Bebop changes the way to extract knowledge from thousands of comments in an easy and fast way!',
    tryFree: 'Try it free!',
    example: 'Examples',
    language: 'Languages',
    languageDescription: 'Bebop has spent too much time traveling the world to be able to analyze comments wherever they come from!',
    supportedLanguages: `Arabic, chinese, english, french, german, italian, japanese, korean, dutch, polish,
      spanish, thai, turkish and russian`,
    platformDisponibility: 'Available platforms',
    platformDescription: 'Bebop currently analyzes youtube and twitter comments but we want to expand our borders!',
    enterpriseCall: 'Do you want to use Bebop for your business processes?',
    team: 'Team',
    feedback: 'We need your feedback!',
    contactUs: 'Contact us',
  },
};
