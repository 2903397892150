import axios from 'axios';

export default {
  sendConsentData(email) {
    const url = `${process.env.VUE_APP_MEDIA_DATA_API_URL}/consent`;

    return axios.post(url, {
      email: email,
    });
  },
};
