import axios from 'axios';

export default {
  sendProcessVideoRequest(videoId, email) {
    const url = `${process.env.VUE_APP_MEDIA_DATA_API_URL}/youtube`;

    return axios.post(url, {
      videoId: videoId,
      email: email,
    },
    {
      headers: { 'Accept-Language': navigator.language },
    });
  },
  getLatestVideos(results) {
    const url = `${process.env.VUE_APP_MEDIA_DATA_API_URL}/youtube/filter/latest_videos?results=${results}`;
    return axios.get(url);
  },
};
