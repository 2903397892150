export default {
  es: {
    showMore: 'Ver más',
    showLess: 'Ver menos',
  },
  en: {
    showMore: 'Show more',
    showLess: 'Show less',
  },
};
