export default {
  videos: [
    {
      thumbnailUrl: 'https://i.ytimg.com/vi/0WVDKZJkGlY/maxresdefault.jpg',
      title: 'Marvel Studios’ Eternals | Official Teaser',
      channelThumbnailUrl: 'https://yt3.ggpht.com/ytc/AKedOLSYVU0j5KAeEwuDpIkgO4LY3y-jHHwaaDMkMTdlDrA=s176-c-k-c0x00ffffff-no-rj',
      channelTitle: 'Marvel Entertainment',
      visualizations: 40258,
      videoID: '0WVDKZJkGlY',
      uri: 'youtube/0WVDKZJkGlY',
      category: {
        es: 'Youtube',
        en: 'Youtube',
      },
    },
    {
      thumbnailUrl: 'https://i.ytimg.com/vi/BtN-goy9VOY/maxresdefault.jpg',
      title: 'The Coronavirus Explained & What You Should Do',
      channelThumbnailUrl: 'https://yt3.ggpht.com/ytc/AKedOLRvMf1ZTTCnC5Wc0EGOVPyrdyvfvs20vtdTUxz_vQ=s176-c-k-c0x00ffffff-no-rj',
      channelTitle: 'Kurzgesagt – In a Nutshell',
      visualizations: 35330,
      videoID: 'BtN-goy9VOY',
      uri: 'youtube/BtN-goy9VOY',
      category: {
        es: 'Youtube',
        en: 'Youtube',
      },
    },
    {
      thumbnailUrl: 'https://i.ytimg.com/vi/4mHq6Y7JSmg/maxresdefault.jpg',
      title: 'Nintendo Switch (OLED model) - Announcement Trailer',
      channelThumbnailUrl: 'https://i.ytimg.com/vi/4mHq6Y7JSmg/maxresdefault.jpg',
      channelTitle: 'Nintendo',
      visualizations: 34635,
      videoID: '4mHq6Y7JSmg',
      uri: 'youtube/4mHq6Y7JSmg',
      category: {
        es: 'Youtube',
        en: 'Youtube',
      },
    },

    {
      thumbnailUrl: 'https://images-ext-2.discordapp.net/external/o_uEsz4hagXW_kMusbPbUOkuunY5c2agdnx8ycTIQOg/https/linuxiac.com/wp-content/uploads/2021/07/valve-steam-deck.png',
      title: 'valve',
      channelThumbnailUrl: 'https://help.twitter.com/content/dam/help-twitter/brand/logo.png',
      channelTitle: 'valve',
      visualizations: 20083,
      videoID: '9b9a07fe989f85da44271253fd6f9e1a',
      uri: 'twitter/9b9a07fe989f85da44271253fd6f9e1a',
      category: {
        es: 'Twitter',
        en: 'Twitter',
      },
    },
    {
      thumbnailUrl: 'https://images-ext-1.discordapp.net/external/mLI6QETEgPTWhOuk8zN5Nklvv8kE0clCCen1glTLKUY/%3Fquality%3D100%26strip%3Dinfo/https/cnnespanol.cnn.com/wp-content/uploads/2021/07/210718135034-protestas-cuba-conecta2-full-169.jpg',
      title: 'cuba',
      channelThumbnailUrl: 'https://help.twitter.com/content/dam/help-twitter/brand/logo.png',
      channelTitle: 'cuba',
      visualizations: 20086,
      videoID: '2b84cd5da64a828bd859b18535c9345e',
      uri: 'twitter/2b84cd5da64a828bd859b18535c9345e',
      category: {
        es: 'Twitter',
        en: 'Twitter',
      },
    },
    {
      thumbnailUrl: 'https://images-ext-1.discordapp.net/external/6t-izcQldxxW-kQbuC-2p4XxYpCnGjumSJ_1su0ayc0/https/media2.malaymail.com/uploads/articles/2021/2021-06/210608-jeff-bezos-space.jpg?width=1386&height=910',
      title: 'jeff bezos',
      channelThumbnailUrl: 'https://help.twitter.com/content/dam/help-twitter/brand/logo.png',
      channelTitle: 'jeff bezos',
      visualizations: 10081,
      videoID: 'f332ae4b9499e93a010f86771e77322c',
      uri: 'twitter/f332ae4b9499e93a010f86771e77322c',
      category: {
        es: 'Twitter',
        en: 'Twitter',
      },
    },
    // {
    //   category: {
    //     es: 'Amazon Reviews',
    //     en: 'Amazon Reviews',
    //   },
    //   isDisabled: true,
    // },
  ],
};
