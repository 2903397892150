<template>
  <div id="sign-up-container text-dark">
    <div class="m-4 shadow p-3">
      <form
        v-show="!enableFormResetPassword"
        id="form-reset-password"
        data-test-id="form-reset-password"
        novalidate
      >
        <div class="form-group">
          <label> {{ getLiterals.resetPasswordComponent.emailToRecover }}</label>
          <p
            class="form-errors text-left"
            v-if="formErrors.email"
            data-test-id="input-error-email-reset-password">
            {{ formErrors.email }}
          </p>
          <input
            type="email"
            class="form-control"
            name="reset-password-email"
            autocomplete="on"
            :class="emailInputValidation"
            placeholder="email"
            v-model="formData.email"
            id="reset-password-email"
            aria-describedby="email account to reset password"
            data-test-id="reset-password-email"
            required
          />
        </div>
        <div class="text-md-center">
          <button
            type="submit" class="btn btn-outline-primary mt-3" href="#"
            data-toggle="modal"
            @click.prevent="sendResetPasswordCode"
            :disabled="isLoading"
            id="button-reset-password-code"
            data-test-id="button-reset-password-code"
          >
          {{ getLiterals.resetPasswordComponent.sendCode }}
        </button>
        </div>
      </form>

      <form
        id="form-sign-up-verification-code"
        data-test-id="form-sign-up-verification-code"
        novalidate
        v-show="enableFormResetPassword"
      >
        <div class="form-group">
          <label>{{ getLiterals.resetPasswordComponent.code }}</label>
          <p
            class="form-errors text-left"
            v-if="formConfirmPasswordErrors.code"
            data-test-id="input-error-text-reset-password-code">
            {{ formConfirmPasswordErrors.code }}
          </p>
          <input
            type="text"
            class="form-control mb-2"
            :class="codeInputValidation"
            name="reset-password-code"
            autocomplete="off"
            :placeholder="getLiterals.resetPasswordComponent.code"
            v-model="formConfirmPassword.code"
            id="reset-password-code"
            aria-describedby="reset password code verification"
            data-test-id="reset-password-code"
            required
          />

          <label>{{ getLiterals.resetPasswordComponent.password }}</label>
            <p
              class="form-errors text-left"
              v-if="formConfirmPasswordErrors.password"
              data-test-id="input-error-reset-new-password">
              {{ formConfirmPasswordErrors.password }}
            </p>
            <input
              type="password"
              class="form-control mb-2"
              name="input-reset-new-password"
              autocomplete="on"
              :class="passwordInputValidation"
              :placeholder="getLiterals.resetPasswordComponent.password"
              v-model="formConfirmPassword.password"
              id="input-reset-new-password"
              aria-describedby="new password"
              data-test-id="input-reset-new-password"
              required
            />

            <label>{{ getLiterals.resetPasswordComponent.confirmPassword }}</label>
            <p
              class="form-errors text-left"
              v-if="formConfirmPasswordErrors.confirmPassword"
              data-test-id="input-error-signup-confirm-new-password">
              {{ formConfirmPasswordErrors.confirmPassword }}
            </p>
            <input
              type="password"
              class="form-control"
              name="input-confirm-new-password"
              autocomplete="on"
              :class="confirmPasswordInputvalidation"
              :placeholder="getLiterals.resetPasswordComponent.confirmPassword"
              v-model="formConfirmPassword.confirmPassword"
              id="input-confirm-new-password"
              aria-describedby="confirm new password"
              data-test-id="input-confirm-new-password"
              required/>
        </div>

        <div class="text-md-center">
          <button
            type="submit" class="btn btn-outline-primary mt-3" href="#"
            data-toggle="modal"
            @click.prevent="confirmResetPassword"
            :disabled="isLoading"
            id="button-update-password"
            data-test-id="button-update-password"
          >
            {{ getLiterals.resetPasswordComponent.updatePassword }}
          </button>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import literalsMixin from '@/mixins/literals_mixin';
import ResetPasswordForm from '../schemas/reset_password_form_joi';
import ResetPasswordConfirmForm from '../schemas/reset_password_confirm_form_joi';
import literals from '../literals/index';
import constants from '../constants/index';

export default {
  name: 'ResetPasswordForm',
  mixins: [literalsMixin],
  data() {
    return {
      enableFormResetPassword: false,
      formConfirmPasswordHasErrors: false,
      formConfirmPasswordErrors: {
        password: '',
        confirmPassword: '',
        code: '',
      },
      formConfirmPassword: {
        password: '',
        confirmPassword: '',
        code: '',
      },
      formHasErrors: false,
      formErrors: {
        email: '',
      },
      formData: {
        email: '',
      },
      isLoading: false,
    };
  },
  computed: {
    emailInputValidation() {
      if (this.formHasErrors) {
        return this.formErrors.email ? 'is-invalid' : 'is-valid';
      }
      return '';
    },
    codeInputValidation() {
      if (this.formConfirmPasswordHasErrors) {
        return this.formConfirmPasswordErrors.code ? 'is-invalid' : 'is-valid';
      }
      return '';
    },
    passwordInputValidation() {
      if (this.formConfirmPasswordHasErrors) {
        return this.formConfirmPasswordErrors.password ? 'is-invalid' : 'is-valid';
      }
      return '';
    },
    confirmPasswordInputvalidation() {
      if (this.formConfirmPasswordHasErrors) {
        return (this.formConfirmPasswordErrors.confirmPassword 
          || this.formConfirmPasswordErrors.password) ? 'is-invalid' : 'is-valid';
      }
      return '';
    },
  },
  mounted() {
    if (this.$store.state.loginEmail) {
      this.formData.email = this.$store.state.loginEmail;
      this.$store.commit('setLoginEmail', null);
    }
  },
  methods: {
    async sendResetPasswordCode() {
      this.formErrors.email = '';
      this.formErrors.password = '';
      this.formHasErrors = false;

      const validation = ResetPasswordForm.validate(this.formData, {
        abortEarly: false,
      });
      if (!validation.error) {
        try {
          this.isLoading = true;
          await Auth.forgotPassword(this.formData.email);
          this.isLoading = false;
          this.enableFormResetPassword = true;
        } catch (error) {
          this.isLoading = false;
          if (error.code) {
            switch (error.code) {
              default:
                this.$store.commit('addToastData', {
                  title: literals.errors[this.$store.state.lang].unkownErrorSendCodeTitle,
                  body: literals.errors[this.$store.state.lang].unkownErrorSendCodeBody,
                  category: constants.toastStatus.error,
                });
                break;
            }
          } else {
            this.$store.commit('addToastData', {
              title: literals.errors[this.$store.state.lang].unkownErrorSendCodeTitle,
              body: literals.errors[this.$store.state.lang].unkownErrorSendCodeBody,
              category: constants.toastStatus.error,
            });
          }
        }
      } else {
        this.formHasErrors = true;
        this.checkFormErrors(validation.error.details);
      }
    },
    async confirmResetPassword() {
      this.formConfirmPasswordErrors.code = '';
      this.formConfirmPasswordErrors.password = '';
      this.formConfirmPasswordErrors.confirmPassword = '';
      this.formConfirmPasswordHasErrors = false;

      const validation = ResetPasswordConfirmForm.validate(this.formConfirmPassword, {
        abortEarly: false,
      });
      if (!validation.error) {
        try {
          this.isLoading = true;
          await Auth.forgotPasswordSubmit(
            this.formData.email,
            this.formConfirmPassword.code,
            this.formConfirmPassword.password,
          );
          this.$store.commit('addToastData', {
            title: literals.resetPasswordComponent[this.$store.state.lang].resetPasswordSucessTitle,
            body: literals.resetPasswordComponent[this.$store.state.lang].resetPasswordSucessBody,
            category: constants.toastStatus.successed,
          });
          this.isLoading = false;
          this.$router.push('/login');
        } catch (error) {
          this.isLoading = false;
          if (error.code) {
            switch (error.code) {
              case 'CodeMismatchException':
                this.$store.commit('addToastData', {
                  title: literals.errors[this.$store.state.lang].confirmCodeErrorTitle,
                  body: literals.errors[this.$store.state.lang].confirmCodeErrorBody,
                  category: constants.toastStatus.error,
                });
                break;
              case 'ExpiredCodeException':
                this.$store.commit('addToastData', {
                  title: literals.errors[this.$store.state.lang].codeExpiredTitle,
                  body: literals.errors[this.$store.state.lang].codeExpiredBody,
                  category: constants.toastStatus.error,
                });
                break;
              default:
                this.$store.commit('addToastData', {
                  title: literals.errors[this.$store.state.lang].errorChangeUserPasswordTitle,
                  body: literals.errors[this.$store.state.lang].errorChangeUserPasswordBody,
                  category: constants.toastStatus.error,
                });
                break;
            }
          } else {
            this.$store.commit('addToastData', {
              title: literals.errors[this.$store.state.lang].errorChangeUserPasswordTitle,
              body: literals.errors[this.$store.state.lang].errorChangeUserPasswordBody,
              category: constants.toastStatus.error,
            });
          }
        }
      } else {
        this.formConfirmPasswordHasErrors = true;
        this.checkConfirmResendFormErrors(validation.error.details);
      }
    },
    checkFormErrors(errors) {
      const self = this;
      errors.forEach((error) => {
        switch (error.context.key) {
          case 'password':
            self.formErrors.password = error.message;
            break;
          case 'email':
            self.formErrors.email = error.message;
            break;
          default:
            break;
        }
      });
    },
    checkConfirmResendFormErrors(errors) {
      const self = this;
      errors.forEach((error) => {
        console.log(error);
        switch (error.context.key) {
          case 'code':
            self.formConfirmPasswordErrors.code = error.message;
            break;
          case 'password':
            self.formConfirmPasswordErrors.password = error.message;
            break;
          case 'confirmPassword':
            self.formConfirmPasswordErrors.confirmPassword = literals
              .errors[this.$store.state.lang].passwordsNotMatch;
            break;
          default:
            break;
        }
      });
    },
  },
};
</script>
