<template>
  <div class="column">
    <div class="container pb-2">
      <div class="row">
        <div class="col-3 col-md-2 col-xl-1 col-video-comment-wrapper">
          <div class="comment-author-thumbnail pr-2 d-inline-block">
            <img loading="lazy" @error="setAltImg" class="youtube-thumbnail"
              :src="getImage">
          </div>
        </div>
        <div class="comment-main col col-video-comment-wrapper">
          <div class="comment-header">
            <p class="mb-0">
              <strong class="font-weight-bold">{{commentData.username}}</strong>
              <span>&nbsp;{{getPublishedDate}}</span>
            </p>
          </div>
          <div id='comment-content'>
            <span class="comments-size">
              <ReadMore :text="commentData.text" :limit=250 />
             </span>
          </div>
            <span class="comment-likes"><i class="fa fa-2 fa-thumbs-up" aria-hidden="true"></i>
              {{commentData.like_count}}
            </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/utils/methods';
import ReadMore from '@/components/ReadMore.vue';
import diagramTooltipMixin from '@/mixins/diagram_tooltip_mixin';

export default {
  components: {
    ReadMore,
  },
  props: {
    commentData: {
      type: Object,
      required: true,
    },
  },
  mixins: [diagramTooltipMixin],
  computed: {
    getPublishedDate() {
      const currentDate = new Date().toISOString();
      return utils.timeDifference(currentDate, this.commentData.created_at);
    },
  },
};
</script>

<style lang="scss" scoped>

#comments-container {
  margin-top: 10%;
}

.comment-main {
  word-wrap: break-word;
  text-align: left;
}

.comment-header {
  padding-bottom: 2px;
}

.youtube-thumbnail {
  border-radius: 50%;
}

.comment-content {
  word-wrap: break-word;
}

.comment-likes {
  font-size: 0.95rem;
  color: grey;
}
</style>
