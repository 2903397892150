export default {
  es: {
    invalidUrlInputErrorMsg: 'Asegurate que sea una url de Youtube como https://youtu.be/2V1fYJntoFA',
    invalidEmailInputErrorMsg: '¡Parece que el email no es correcto! Prueba con algo como: ejemplo@email.com',
    serverUknownError: 'Ha ocurrido un error al procesar la solicitud',
    videoIsProcessingSucessfull: '¡Bebop está procesando la solicitud, te avisaremos en cuanto lo tengamos!',
    videoIsAlreadyProccesed: '¡Bebop ha sido capaz de predecir lo que querias buscar y aquí lo tienes directamente!',
    ToastVideoProcessingTitle: 'Resultado de la solicitud',
    twitterSearchInput: 'La búsqueda debe tener mínimo 1 letra y un máximo de 512',
    loginPasswordRequired: 'La contraseña es obligatoria',
    invalidCodeField: 'Revisa por favor que has introducido correctamente el código de confirmación recibido en el correo',
  },
  en: {
    invalidUrlInputErrorMsg: 'Make sure that url is a valid Youtube url like https://youtu.be/2V1fYJntoFA',
    invalidEmailInputErrorMsg: 'Its seem that email is not correct! Please try something like example@email.com',
    serverUknownError: 'Some error happens while processing the request',
    videoIsProcessingSucessfull: 'Bebop is processing the request, we will notify you as soon as we get it!',
    videoIsAlreadyProccesed: '',
    videoIsProcessingError: 'Bebop has been able to predict what you want and here you can get now!',
    ToastVideoProcessingTitle: 'Request result',
    twitterSearchInput: 'The search must have a minimum of 1 letter and a maximum of 512',
    loginPasswordRequired: 'Password is required',
    invalidCodeField: 'Please check that you have correctly entered the confirmation code received in the email',
  },
};
