const isValidUrl = (url, hostname) => {
  try {
    const validatedUrl = new URL(String(url));
    if (validatedUrl.hostname.includes(hostname)) {
      return validatedUrl;
    }
  } catch (e) {
    return null;
  }
  return null;
};

const getYoutubeVideoId = (url) => {
  try {
    const validatedUrl = new URL(String(url));
    if (validatedUrl.searchParams.get('v')) {
      return validatedUrl.searchParams.get('v');
    }
    const paths = validatedUrl.pathname.split('/');

    if (paths.length >= 1) {
      // Index 0 empty string

      if (paths[1] && paths.length < 3) {
        return paths[1];
      }

      if (paths[1] === 'embed' && paths[2]) {
        return paths[2];
      }

      if (paths[1] === 'v' && paths[2]) {
        return paths[2];
      }
    }
  } catch (e) {
    return null;
  }
  return null;
};

const timeDifference = (current, previous) => {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;
  const elapsed = new Date(current) - new Date(previous);

  let timeUnit = `${Math.round(elapsed / msPerYear)} years ago`;

  if (elapsed < msPerMinute) {
    timeUnit = `${Math.round(elapsed / 1000)} seconds ago`;
  } else if (elapsed < msPerHour) {
    timeUnit = `${Math.round(elapsed / msPerMinute)} minutes ago`;
  } else if (elapsed < msPerDay) {
    timeUnit = `${Math.round(elapsed / msPerHour)} hours ago`;
  } else if (elapsed < msPerMonth) {
    timeUnit = `${Math.round(elapsed / msPerDay)} days ago`;   
  } else if (elapsed < msPerYear) {
    timeUnit = `${Math.round(elapsed / msPerMonth)} months ago`;   
  }
  return timeUnit;  
};

const getLang = (lang) => {
  const baseLang = lang.split('-');

  if (baseLang[0] === 'es') {
    return 'es';
  }

  return 'en';
};

export default {
  isValidUrl,
  getYoutubeVideoId,
  timeDifference,
  getLang,
};
