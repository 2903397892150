<template>
  <div class="text-center pt-5 pb-2" >
    <div class="container">
      <div class="row">
        <div class="mx-auto col-md-8">
          <h1 class="mb-3" v-html="getLiterals.about.objetive"></h1>
          <p class="lead" v-html="getLiterals.about.bebopDescription"></p>
        </div>
      </div>
    </div>
  </div>
  <div class="py-3">
    <div class="container">
      <div class="row my-4 d-flex justify-content-center align-items-center">
        <div class="d-flex flex-column justify-content-center p-3 col-lg-7">
          <h2 v-html="getLiterals.about.problemTitle"></h2>
          <p class="mb-0" v-html="getLiterals.about.problemDescription"></p>
        </div>
        <div class="p-0 col-lg-3"> <img class="img-fluid d-block" 
        src="../assets/Dark-analytics-amico.png"> </div>
      </div>
      <div class="row my-4 d-flex justify-content-center">
        <div class="p-0 order-2 order-lg-1 col-lg-3"> <img class="img-fluid d-block" 
        src="../assets/Push-notifications-amico.png"> </div>
        <div class="d-flex flex-column justify-content-center p-3 col-lg-7 order-1 order-lg-2">
          <h2 v-html="getLiterals.about.todayTitle"></h2>
          <p class="mb-0" v-html="getLiterals.about.todayDescription"></p>
        </div>
      </div>
      <div class="row my-4 d-flex justify-content-center">
        <div class="d-flex flex-column justify-content-center p-3 col-lg-7">
          <h2 v-html="getLiterals.about.changeTitle"></h2>
          <p class="mb-0" v-html="getLiterals.about.changeDescription"></p>
        </div>
        <div class="p-0 col-lg-3"> <img class="img-fluid d-block" 
        src="../assets/Robotics-amico.png"> </div>
      </div>
    </div>
  </div>
</template>

<script>
import literalsMixin from '@/mixins/literals_mixin';

export default {
  mixins: [literalsMixin],
};
</script>
