/* eslint-disable no-param-reassign */

import Joi from 'joi';
import store from '../store/index';
import literals from '../literals/index';

const signUpFormResendSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'string.empty':
            err.message = literals.forms[store.state.lang].invalidEmailInputErrorMsg;
            break;
          case 'string.base':
            err.message = literals.forms[store.state.lang].invalidEmailInputErrorMsg;
            break;
          case 'string.email':
            err.message = literals.forms[store.state.lang].invalidEmailInputErrorMsg;
            break;
          case 'any.required':
            err.message = literals.forms[store.state.lang].invalidEmailInputErrorMsg;
            break;
          default:
            err.message = literals.forms[store.state.lang].invalidEmailInputErrorMsg;
            break;
        }
      });
      return errors;
    }),
});
export default signUpFormResendSchema;
