export default {
  sentimentBarChart: {
    series: [],
    chart: {
      type: 'bar',
      width: '100%',
      height: '30%',
      stacked: true,
      stackType: '100%',
    },
    responsive: [{
      breakpoint: 576,
      options: {
        chart: {
          width: '100%',
          height: '50%',
        },
      },
    }],
    colors: ['#a8e6cf', 'grey', '#ff8b94'],
    // colors: [function (data) {
    //   if (data.dataPointIndex === 0) {
    //     return '#a8e6cf';
    //   }

    //   if (data.dataPointIndex === 1) {
    //     return 'grey';
    //   }

    //   return '#ff8b94';
    // }],
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
  },
  commentsHistogramChart: {
    series: [],
    chart: {
      id: 'histogram',
      width: '100%',
      height: '30%',
      type: 'line',
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      animations: {
        enabled: false,
        speed: 500,
        animateGradually: {
          enabled: false,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    responsive: [{
      breakpoint: 576,
      options: {
        chart: {
          width: '100%',
          height: '50%',
        },
      },
    }],
    stroke: {
      curve: 'smooth',
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      decimalsInFloat: 0,
    },
  },
  keywordsChart: {
    chart: {
      type: 'bar',
      width: '100%',
      height: '30%',
    },
    responsive: [{
      breakpoint: 576,
      options: {
        chart: {
          width: '100%',
          height: '50%',
        },
      },
    }],
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
  },
};
