<template>
  <div class="my-5 d-flex justify-content-center align-items-center">
    <div class="col col-md-4">
      <LoginForm/>
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue';

export default {
  name: 'Login',
  components: {
    LoginForm,
  },
};
</script>
