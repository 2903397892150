<template>
<div id="tooltip-diagram-info" class="tooltip-diagram">
  <div class="container tooltip-diagram-container">
    <div class="row">
      <div class="col-3 tooltip-thumbnail-container">
        <div id="tooltip-author-thumbnail">
          <img loading="lazy" :src="getImage" @error="setAltImg" id="image-author-profile"/>
        </div>
      </div>

      <div id="tooltip-main" class="col">
        <div id=tooltip-header>
          <p class="mb-0">
            <strong>{{commentData.username}}</strong>
            <span>&nbsp;{{getPublishedDate}}</span>
          </p>
        </div>
        <div id="tooltip-content">{{commentData.text}}</div>
        <div id="tooltip-likes">
          <i class="fa fa-2 fa-thumbs-up" aria-hidden="true"></i>&nbsp;{{commentData.like_count}}
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import diagramTooltipMixin from '@/mixins/diagram_tooltip_mixin';

export default {
  name: 'DiagramTooltip',
  props: {
    commentData: {
      type: Object,
      required: true,
    },
  },
  mixins: [diagramTooltipMixin],
};
</script>

<style lang="scss" scoped>

</style>
