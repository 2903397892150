/* eslint-disable no-param-reassign */
import Joi from 'joi';
import store from '../store/index';
import literals from '../literals/index';
import constants from '../constants/index';

const twitterPostSchema = Joi.object({
  searchText: Joi.string()
    .trim()
    .required()
    .max(constants.TWEET_MAX_SEARCH_LIMIT)
    .min(constants.TWEET_MIN_SEARCH_LIMIT)
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'string.empty':
            err.message = literals.forms[store.state.lang].twitterSearchInput;
            break;
          case 'string.base':
            err.message = literals.forms[store.state.lang].twitterSearchInput;
            break;
          case 'any.required':
            err.message = literals.forms[store.state.lang].twitterSearchInput;
            break;
          case 'string.max':
            err.message = literals.forms[store.state.lang].twitterSearchInput;
            break;
          case 'string.min':
            err.message = literals.forms[store.state.lang].twitterSearchInput;
            break;
          default:
            err.message = literals.forms[store.state.lang].twitterSearchInput;
            break;
        }
      });
      return errors;
    }),
  email: Joi.string()
    .trim()
    .email({ tlds: { allow: false } })
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'string.empty':
            err.message = literals.forms[store.state.lang].invalidEmailInputErrorMsg;
            break;
          case 'string.base':
            err.message = literals.forms[store.state.lang].invalidEmailInputErrorMsg;
            break;
          case 'string.email':
            err.message = literals.forms[store.state.lang].invalidEmailInputErrorMsg;
            break;
          case 'any.required':
            err.message = literals.forms[store.state.lang].invalidEmailInputErrorMsg;
            break;
          default:
            err.message = literals.forms[store.state.lang].invalidEmailInputErrorMsg;
            break;
        }
      });
      return errors;
    }),
  consentCheck: Joi.boolean().required(),
  consentCheckMarketing: Joi.boolean(),
});

export default twitterPostSchema;
