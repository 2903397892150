<template>
  <div class="py-5">
    <div class="container">
      <div class="row">
        <div class="table-responsive">
          <table class="table text-center">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th class="text-center">
                  <h3>Free</h3>
                  <h2><b>0€</b></h2>
                  <router-link
                    to="/"
                    v-slot="{ href, navigate}" custom>
                    <a class="btn btn-primary"
                      :href="href" @click="navigate">{{ getLiterals.pricing.wannaTry }}
                    </a>
                  </router-link>
                </th>
                <th class="text-center">
                  <h3>Pro</h3>
                  <h2><b>-</b></h2>
                  <router-link
                    to="/contact"
                    v-slot="{ href, navigate}" custom>
                    <a class="btn btn-link"
                      :href="href" @click="navigate">{{ getLiterals.pricing.contactUs }}
                    </a>
                  </router-link>
                </th>
                <th class="text-center">
                  <h3>Enterprise</h3>
                  <h2><b>-</b></h2>
                  <router-link
                    to="/contact"
                    v-slot="{ href, navigate}" custom>
                    <a class="btn btn-link"
                      :href="href" @click="navigate">{{ getLiterals.pricing.contactUs }}
                    </a>
                  </router-link>
                </th>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>{{ getLiterals.pricing.forCurious }}</td>
                <td >{{ getLiterals.pricing.forProfessionals }}</td>
                <td>{{ getLiterals.pricing.forAgencies }}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ getLiterals.pricing.platformsDisponibility }}</td>
                <td><i class="fa fa-youtube-play fa-lg text-muted"></i></td>
                <td>
                  <i class="fa fa-youtube-play text-muted fa-lg mx-2"></i
                  ><i class="fa fa-twitter text-muted fa-lg mr-2"></i
                  ><i class="fa fa-twitch text-muted fa-lg mr-2" ></i>
                </td>
                <td>
                  <i class="fa fa-youtube-play text-muted fa-lg mx-2"></i
                  ><i class="fa fa-twitter text-muted fa-lg mr-2"></i
                  ><i class="fa fa-twitch text-muted fa-lg mr-2" ></i>
                </td>
              </tr>
              <tr>
                <td>{{ getLiterals.pricing.commentsLimits }}</td>
                <td>
                  <p class="my-0">4000</p>
                </td>
                <td>
                  <p class="my-0">{{ getLiterals.pricing.withoutLimit }}</p>
                </td>
                <td>
                  <p class="my-0">{{ getLiterals.pricing.withoutLimit }}</p>
                </td>
              </tr>
              <tr>
                <td>{{ getLiterals.pricing.dayLimit }}</td>
                <td>
                  <p class="my-0">3</p>
                </td>
                <td>
                  <p class="my-0">{{ getLiterals.pricing.withoutLimit }}</p>
                </td>
                <td>
                  <p class="my-0">{{ getLiterals.pricing.withoutLimit }}</p>
                </td>
              </tr>
              <tr>
                <td>{{ getLiterals.pricing.sentimentAnalysis }}</td>
                <td><i class="fa fa-check fa-lg text-muted"></i></td>
                <td ><i class="fa fa-check fa-lg text-muted"></i></td>
                <td><i class="fa fa-check fa-lg text-muted"></i></td>
              </tr>
              <tr>
                <td>{{ getLiterals.pricing.automaticGroup }}</td>
                <td><i class="fa fa-check fa-lg text-muted"></i></td>
                <td><i class="fa fa-check fa-lg text-muted"></i></td>
                <td><i class="fa fa-check fa-lg text-muted"></i></td>
              </tr>
              <tr>
                <td>{{ getLiterals.pricing.updateAndTracking }}</td>
                <td></td>
                <td></td>
                <td><i class="fa fa-check fa-lg text-muted"></i></td>
              </tr>
              <tr>
                <td>{{ getLiterals.pricing.personalization }}</td>
                <td></td>
                <td></td>
                <td><i class="fa fa-check fa-lg text-muted"></i></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import literalsMixin from '@/mixins/literals_mixin';

export default {
  mixins: [literalsMixin],
};
</script>
