export default {
  es: {
    instructionTitle: 'Instrucciones de uso',
    howAnalyze: '¿Cómo analizo un vídeo?',
    analyzeFirstStep: `Rellena el formulario de la página principal introduciendo el vídeo del que 
      quieres analizar los comentarios y el correo donde quieres que mandemos los resultados.`,
    analyzeSecondStep: `La primera vista que tendrás al acceder a la herramienta es una vista general de los comentarios del vídeo. 
      A la izquierda aparece un mapa de puntos y a la derecha una caja de comentarios.`,
    howOrganizeComments: '¿Cómo se organizan los comentarios?',
    organizeStep: 'Cada punto es un comentario, cuanto más cerca estén los puntos, más se parecen o hablan de algo similar.',
    whatSizeMeans: '¿Qué significa el tamaño de los puntos?',
    whatSizeDescription: 'Cuanto más grande sea el punto mayor cantidad de likes tiene el comentario.',
    whatColorsMeans: '¿Qué significan los colores?',
    whatColorAllSelected: 'Cuando está seleccionada la opción "Todos":',
    whatColorsMeansPositive: 'Si el punto es verde contiene palabras positivas.',
    whatColorsMeansNegative: 'Si el punto es rojo contiene palabras negativas.',
    whatColorsMeansNeutral: 'Si el punto es gris Bebop no se quiere mojar porque no está seguro si es positivo o negativo.',
    whatColorGroupSelected: 'Por el contrario si está seleccionada la opción "Grupos" cada comentario que tenga el mismo color que otro representa un comentario que está hablando del mismo tema.',
    howInteractWithTheMap: '¿Cómo interactúo con el mapa?',
    howInteractFirstParagraph: 'Si pasas el cursor sobre cualquier punto aparece el propio comentario con la imagen y nombre del usuario que lo escribió.',
    howInteractSecondParagraph: `Usando el botón izquierdo del ratón y arrastrando puedes seleccionar una zona para ampliarla. A su vez la caja de comentarios se
      actualiza para mostrar solo esos comentarios.`,
    howInteractThirdParagraph: 'Si quieres quitar el zoom puedes pulsar dos veces el botón izquierdo del ratón sobre el mapa.',
  },
  en: {
    instructionTitle: 'Use Instruction',
    howAnalyze: 'How i can analyze a video?',
    analyzeFirstStep: `Fill the form from the home page, insert the vídeo that you want to analyze the comments
      and the email where you want to receive the result`,
    analyzeSecondStep: `The first look when you access to the tool its a general view of the comments from the video.
      In the left you can look to a scatterplot map and to the rigth a box with the comments.`,
    howOrganizeComments: 'How the comments are organized?',
    organizeStep: 'Every point in the map is a comment, more close are the points, more related are the comments',
    whatSizeMeans: '¿Whats means the size of the points?',
    whatSizeDescription: 'When more big is the point, more likes have the comment.',
    whatColorsMeans: '¿Whats colors means?',
    whatColorAllSelected: 'When the "All" option is selected:',
    whatColorsMeansPositive: 'If the point is green contains positive words.',
    whatColorsMeansNegative: 'If the point is red contains negative words.',
    whatColorsMeansNeutral: 'If the point is grey Bebop its not sure if its positive or negative.',
    whatColorGroupSelected: 'But if the "Groups" option is selected, each comment that has the same color as another represents a comment that is talking about the same topic.',
    howInteractWithTheMap: 'How i can interact with the map?',
    howInteractFirstParagraph: 'If you hover over any point, the comment itself appears with the image and name of the user who wrote it.',
    howInteractSecondParagraph: `Using the left mouse button and dragging you can select an area to enlarge it. At the same time, the comment box is
      update to show only those comments.`,
    howInteractThirdParagraph: 'If you want to zoom out, you can double-click the left mouse button over the map.',
  },
};
