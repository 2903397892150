export default {
  es: {
    bebopDescription: '¡Bebop organiza y simplifica miles de comentarios!',
    videoToAnalyze: '¿Qué vídeo quieres analizar?',
    textToAnalyze: '¿Qué palabra quieres analizar?',
    whereSendResult: '¿Dónde mandamos los resultados?',
    agreeConditions: 'Acepto que se use mi correo únicamente para recibir los resultados',
    wannaTry: 'Analizar',
  },
  en: {
    bebopDescription: 'Bebop organizes and simplifies thousands of comments!',
    videoToAnalyze: 'Which video do you want to analyze?',
    textToAnalyze: 'Which word do you want to analyze?',
    whereSendResult: 'Where do we send the results?',
    agreeConditions: 'I agree that my email is used only to receive the results',
    wannaTry: 'Analyze',
  },
};
