export default {
  es: {
    password: 'Contraseña',
    confirmPassword: 'Confirmar contraseña',
    marketingAgree: 'Quiero recibir por correo electrónico actualizaciones de Bebop y ofertas especiales.',
    signUpSucessTitle: 'Usuario confirmado',
    signUpSucessBody: 'El usuario ha sido verificado correctamente',
    signUp: 'Registrarse',
    code: 'Código',
    resendCode: 'Reenviar Código',
    confirm: 'Confirmar',
  },
  en: {
    password: 'Password',
    confirmPassword: 'Confirm password',
    marketingAgree: 'I want to receive Bebop updates and special offers by email.',
    signUpSucessTitle: 'User confirmed',
    signUpSucessBody: 'User has been verified',
    signUp: 'Sign Up',
    code: 'Code',
    resendCode: 'Resend Code',
    confirm: 'Confirm',
  },
};
