import { createStore } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

export default createStore({
  state() {
    return {
      loginEmail: null,
      s3SignedUrl: null,
      scatterCommentsData: null,
      scatterCommentsFilterData: null,
      toastData: [],
      modalMessage: null,
      modalImage: null,
      statusAlreadyReceived: false,
      lang: 'en',
      enableFormVerificationCode: false,
    };
  },
  mutations: {
    setLanguage(state, lang) {
      const langBase = lang.split('-');
      if (langBase[0] === 'es') {
        state.lang = 'es';
      } else {
        state.lang = 'en';
      }
    },
    setLoginEmail(state, email) {
      state.loginEmail = email;
    },
    setEnableFormVerificationCode(state, enable) {
      state.enableFormVerificationCode = enable;
    },
    addScatterCommentsData(state, data) {
      state.scatterCommentsData = data;
    },
    addScatterCommentsFilterData(state, data) {
      state.scatterCommentsFilterData = data;
    },
    addS3SignedUrl(state, data) {
      state.s3SignedUrl = data;
    },
    changeModalMessage(state, data) {
      state.modalMessage = data;
    },
    changeModalImage(state, data) {
      state.modalImage = data;
    },
    changeStatusReceived(state, data) {
      state.statusAlreadyReceived = data;
    },
    addToastData(state, data) {
      state.toastData.push({
        title: data.title,
        body: data.body,
        category: data.category,
        time: data.time,
        id: uuidv4(),
      });
    },
    removeToastData(state, data) {
      state.toastData = state.toastData.filter((element) => element.id !== data.id);
    },
  },
  actions: {
  },
  modules: {
  },
});
